export const usdNumFmt = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  compactDisplay: 'short',
});

export function formatShortUSD(value: number) {
  return value >= 1000000000
    ? '$' + Math.round(value / 10000000) / 100 + 'B'
    : value >= 1000000
    ? '$' + Math.round(value / 10000) / 100 + 'M'
    : value >= 1000
    ? '$' + Math.round(value / 10) / 100 + 'K'
    : value;
}
