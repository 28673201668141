import { Chart } from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import fundingData from '../../data/indonesian-tech-funding.json';
import { initChart } from '../bubbleChart';
import { formatShortUSD } from '../currency';
import { filterDatasetsMap } from '../dataset';
import magnifierImg from '../magnifier.svg';
import { createExternalTooltipHandler } from '../tooltip';
import {
  FundingEvent,
  generateDatasetsMap,
  generateFundingInfoList,
} from './data';

Chart.defaults.elements.point.hitRadius = 15;

const datasetsMap = generateDatasetsMap(fundingData as FundingEvent[]);
initChart<FundingEvent>({
  datasetsMap,
  xTicksCallback: (value) => formatShortUSD(value as number),
  externalTooltipHandler: createExternalTooltipHandler<FundingEvent>((d) => {
    const row = d.data;
    const infoData = generateFundingInfoList(row);
    infoData.push(
      `<a style="color: #1d63dc; text-decoration: none;" href="https://www.google.com/search?q=${encodeURIComponent(
        infoData.join(' ')
      )}" target="_blank"><img src="${magnifierImg}" width="11px" height="11px" alt="magnifier" /> <span style="text-decoration: underline;">Google Search</span></a>`
    );
    return infoData.join('<br />');
  }),
  onSearch: (keyword) => {
    return filterDatasetsMap<FundingEvent>(keyword, datasetsMap, (r) => {
      return `${r.lead_investors} ${r.date}`
        .toLowerCase()
        .includes(keyword.toLowerCase());
    });
  },
});
