import { format } from 'date-fns';
import randomColor from 'randomcolor';
import { formatShortUSD } from '../currency';
import { CompanyDatasetsMap } from '../dataset';

export interface FundingEvent {
  date: string;
  company_name: string;
  funding_name: string;
  funding_amount: number | 'unknown';
  total_investors: number | 'unknown';
  lead_investors: string | 'unknown';
}

export function generateFundingInfoList(row: FundingEvent) {
  const infoData = [row.company_name];
  if (row.funding_amount !== 'unknown') {
    infoData.push(
      formatShortUSD(row.funding_amount).toString() + ' ' + row.funding_name
    );
  }
  if (row.lead_investors !== 'unknown') {
    infoData.push(`Led by ${row.lead_investors}`);
  }
  infoData.push(format(new Date(row.date), 'MMMM d, yyyy'));
  return infoData;
}

export function generateDatasetsMap(fundingData: FundingEvent[]) {
  const datasetsMap: CompanyDatasetsMap<FundingEvent> = {};
  for (const row of fundingData) {
    if (datasetsMap[row.company_name] === undefined) {
      datasetsMap[row.company_name] = {
        label: row.company_name,
        data: [],
        backgroundColor: randomColor({
          luminosity: 'dark',
          alpha: 0.7,
          format: 'rgba',
          seed: row.company_name,
        }),
      };
    }
    const infoData = generateFundingInfoList(row);
    let fundingAmount =
      row.funding_amount == 'unknown' ? 0 : row.funding_amount;
    infoData.push(
      `https://www.google.com/search?q=${encodeURIComponent(
        infoData.join(' ')
      )}`
    );
    datasetsMap[row.company_name].data.push({
      data: row,
      y: new Date(row.date).getTime(),
      x: fundingAmount,
      r: 1,
    });
  }
  return datasetsMap;
}
