[
  {
    "date": "2009-02-06",
    "company_name": "Tokopedia",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Indonusa Dwitama"
  },
  {
    "date": "2010-03-15",
    "company_name": "Tokopedia",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2011-04-19",
    "company_name": "Tokopedia",
    "funding_name": "Series B",
    "funding_amount": 700000,
    "total_investors": 1,
    "lead_investors": "CyberAgent Capital"
  },
  {
    "date": "2011-07-02",
    "company_name": "Bukalapak",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2012-04-04",
    "company_name": "Tokopedia",
    "funding_name": "Series C",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Beenos Partners"
  },
  {
    "date": "2012-05-10",
    "company_name": "99.co",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2012-09-11",
    "company_name": "Bukalapak",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2012-11-12",
    "company_name": "Traveloka",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2013-06-05",
    "company_name": "99.co",
    "funding_name": "Series A",
    "funding_amount": 2000000,
    "total_investors": 4,
    "lead_investors": "unknown"
  },
  {
    "date": "2013-06-12",
    "company_name": "Tokopedia",
    "funding_name": "Series D",
    "funding_amount": "unknown",
    "total_investors": 4,
    "lead_investors": "Softbank Ventures Asia"
  },
  {
    "date": "2013-08-15",
    "company_name": "Traveloka",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Global Founders Capital"
  },
  {
    "date": "2013-09-05",
    "company_name": "Qraved",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 3,
    "lead_investors": "unknown"
  },
  {
    "date": "2014-02-01",
    "company_name": "INDODAX",
    "funding_name": "Angel Round",
    "funding_amount": 50000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2014-02-03",
    "company_name": "Cakap",
    "funding_name": "Angel Round",
    "funding_amount": "unknown",
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2014-02-13",
    "company_name": "Bukalapak",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 5,
    "lead_investors": "unknown"
  },
  {
    "date": "2014-05-10",
    "company_name": "Akulaku",
    "funding_name": "Seed Round",
    "funding_amount": 1300000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2014-05-16",
    "company_name": "Ralali",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2014-08-18",
    "company_name": "Ruangguru",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2014-09-22",
    "company_name": "Moka",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2014-10-21",
    "company_name": "Tokopedia",
    "funding_name": "Series E",
    "funding_amount": 100000000,
    "total_investors": 3,
    "lead_investors": "SoftBank Telecom Corp"
  },
  {
    "date": "2014-11-22",
    "company_name": "eFishery",
    "funding_name": "Seed Round",
    "funding_amount": 1200000,
    "total_investors": 2,
    "lead_investors": "Aqua Spark"
  },
  {
    "date": "2014-11-26",
    "company_name": "Qraved",
    "funding_name": "Series A",
    "funding_amount": 1300000,
    "total_investors": 2,
    "lead_investors": "Convergence Ventures, M & Y Growth Partners"
  },
  {
    "date": "2014-12-31",
    "company_name": "Gojek",
    "funding_name": "Series A",
    "funding_amount": 2000000,
    "total_investors": 2,
    "lead_investors": "Openspace"
  },
  {
    "date": "2015-01-01",
    "company_name": "Travelio",
    "funding_name": "Pre Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2015-01-01",
    "company_name": "Sociolla",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2015-02-01",
    "company_name": "Deliveree Logistics",
    "funding_name": "Seed Round",
    "funding_amount": 1300000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2015-02-04",
    "company_name": "Bukalapak",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "Emtek Group"
  },
  {
    "date": "2015-02-05",
    "company_name": "HijUp",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 3,
    "lead_investors": "unknown"
  },
  {
    "date": "2015-03-01",
    "company_name": "Snapcart",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "unknown"
  },
  {
    "date": "2015-03-10",
    "company_name": "HappyFresh",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 4,
    "lead_investors": "unknown"
  },
  {
    "date": "2015-03-12",
    "company_name": "Elevenia",
    "funding_name": "Funding Round",
    "funding_amount": 18300000,
    "total_investors": 2,
    "lead_investors": "unknown"
  },
  {
    "date": "2015-04-15",
    "company_name": "PrivyID",
    "funding_name": "Pre Seed Round",
    "funding_amount": 10000,
    "total_investors": 1,
    "lead_investors": "Indigo by Telkom Indonesia"
  },
  {
    "date": "2015-04-21",
    "company_name": "Alodokter",
    "funding_name": "Seed Round",
    "funding_amount": 600000,
    "total_investors": 4,
    "lead_investors": "unknown"
  },
  {
    "date": "2015-05-16",
    "company_name": "Moka",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2015-06-01",
    "company_name": "Xendit",
    "funding_name": "Non Equity Assistance",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2015-06-08",
    "company_name": "Ralali",
    "funding_name": "Series A",
    "funding_amount": 2500000,
    "total_investors": 4,
    "lead_investors": "Beenos Partners, CyberAgent Capital"
  },
  {
    "date": "2015-07-06",
    "company_name": "Bizzy",
    "funding_name": "Seed Round",
    "funding_amount": 2500000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2015-07-07",
    "company_name": "Fabelio",
    "funding_name": "Seed Round",
    "funding_amount": 500000,
    "total_investors": 5,
    "lead_investors": "500 Global"
  },
  {
    "date": "2015-07-10",
    "company_name": "Akulaku",
    "funding_name": "Seed Round",
    "funding_amount": 1500000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2015-07-22",
    "company_name": "Cermati",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2015-07-22",
    "company_name": "HijUp",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 3,
    "lead_investors": "unknown"
  },
  {
    "date": "2015-08-18",
    "company_name": "Xendit",
    "funding_name": "Seed Round",
    "funding_amount": 120000,
    "total_investors": 4,
    "lead_investors": "Y Combinator"
  },
  {
    "date": "2015-09-08",
    "company_name": "eFishery",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "unknown"
  },
  {
    "date": "2015-09-14",
    "company_name": "HappyFresh",
    "funding_name": "Series A",
    "funding_amount": 10000000,
    "total_investors": 8,
    "lead_investors": "Vertex Ventures"
  },
  {
    "date": "2015-09-23",
    "company_name": "Stockbit",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Ideosource VC"
  },
  {
    "date": "2015-10-06",
    "company_name": "Lemonilo",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2015-10-15",
    "company_name": "Gojek",
    "funding_name": "Series B",
    "funding_amount": 26000000,
    "total_investors": 3,
    "lead_investors": "unknown"
  },
  {
    "date": "2015-10-19",
    "company_name": "Xendit",
    "funding_name": "Venture Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2015-11-11",
    "company_name": "Bhinneka.Com",
    "funding_name": "Venture Round",
    "funding_amount": 22000000,
    "total_investors": 1,
    "lead_investors": "Ideosource VC"
  },
  {
    "date": "2015-11-16",
    "company_name": "Sociolla",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2015-12-09",
    "company_name": "Ruangguru",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Venturra"
  },
  {
    "date": "2016-01-05",
    "company_name": "Snapcart",
    "funding_name": "Seed Round",
    "funding_amount": 1700000,
    "total_investors": 4,
    "lead_investors": "SPH Media Fund, Wavemaker Partners"
  },
  {
    "date": "2016-01-17",
    "company_name": "Deliveree Logistics",
    "funding_name": "Seed Round",
    "funding_amount": 2500000,
    "total_investors": 2,
    "lead_investors": "unknown"
  },
  {
    "date": "2016-01-27",
    "company_name": "Fabelio",
    "funding_name": "Series A",
    "funding_amount": 2000000,
    "total_investors": 3,
    "lead_investors": "Venturra"
  },
  {
    "date": "2016-01-29",
    "company_name": "FinAccel",
    "funding_name": "Pre Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2016-02-01",
    "company_name": "Akulaku",
    "funding_name": "Series A",
    "funding_amount": 5000000,
    "total_investors": 3,
    "lead_investors": "unknown"
  },
  {
    "date": "2016-02-01",
    "company_name": "Elevenia",
    "funding_name": "Venture Round",
    "funding_amount": 50000000,
    "total_investors": 2,
    "lead_investors": "SK Planet, XL Axiata Tbk"
  },
  {
    "date": "2016-02-23",
    "company_name": "Orami",
    "funding_name": "Series A",
    "funding_amount": 15000000,
    "total_investors": 4,
    "lead_investors": "SMDV"
  },
  {
    "date": "2016-04-01",
    "company_name": "Gojek",
    "funding_name": "Series C",
    "funding_amount": 170000000,
    "total_investors": 2,
    "lead_investors": "unknown"
  },
  {
    "date": "2016-04-04",
    "company_name": "Cakap",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Prasetia Dwidharma"
  },
  {
    "date": "2016-04-04",
    "company_name": "Agate",
    "funding_name": "Seed Round",
    "funding_amount": 1000000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2016-04-11",
    "company_name": "Tokopedia",
    "funding_name": "Series E",
    "funding_amount": 147000000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2016-04-19",
    "company_name": "Modalku",
    "funding_name": "Venture Round",
    "funding_amount": 1200000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2016-04-29",
    "company_name": "Orami",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2016-05-11",
    "company_name": "Ruangguru",
    "funding_name": "Non Equity Assistance",
    "funding_amount": 50000,
    "total_investors": 1,
    "lead_investors": "Google Launchpad Accelerator"
  },
  {
    "date": "2016-05-11",
    "company_name": "HijUp",
    "funding_name": "Non Equity Assistance",
    "funding_amount": 50000,
    "total_investors": 1,
    "lead_investors": "Google Launchpad Accelerator"
  },
  {
    "date": "2016-06-01",
    "company_name": "FinAccel",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 8,
    "lead_investors": "Jungle Ventures"
  },
  {
    "date": "2016-06-01",
    "company_name": "Investree",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Kejora Capital"
  },
  {
    "date": "2016-06-02",
    "company_name": "Moka",
    "funding_name": "Series A",
    "funding_amount": 1900000,
    "total_investors": 3,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2016-06-15",
    "company_name": "Fabelio",
    "funding_name": "Series A",
    "funding_amount": 1000000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2016-08-04",
    "company_name": "Gojek",
    "funding_name": "Series D",
    "funding_amount": 550000000,
    "total_investors": 10,
    "lead_investors": "Kohlberg Kravis Roberts, Warburg Pincus"
  },
  {
    "date": "2016-08-05",
    "company_name": "Alodokter",
    "funding_name": "Series A",
    "funding_amount": 2500000,
    "total_investors": 3,
    "lead_investors": "Golden Gate Ventures"
  },
  {
    "date": "2016-08-05",
    "company_name": "Qraved",
    "funding_name": "Series B",
    "funding_amount": 8000000,
    "total_investors": 8,
    "lead_investors": "Gobi Partners, Richmond Global Ventures"
  },
  {
    "date": "2016-08-29",
    "company_name": "Travelio",
    "funding_name": "Seed Round",
    "funding_amount": 2000000,
    "total_investors": 5,
    "lead_investors": "Gobi Partners"
  },
  {
    "date": "2016-08-29",
    "company_name": "HappyFresh",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 6,
    "lead_investors": "Samena Capital"
  },
  {
    "date": "2016-09-09",
    "company_name": "Cermati",
    "funding_name": "Series A",
    "funding_amount": 1900000,
    "total_investors": 2,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2016-09-09",
    "company_name": "Cermati",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2016-09-09",
    "company_name": "Halodoc",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 6,
    "lead_investors": "Clermont Group"
  },
  {
    "date": "2016-09-26",
    "company_name": "Amartha",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2016-11-15",
    "company_name": "Dekoruma",
    "funding_name": "Series A",
    "funding_amount": 1600000,
    "total_investors": 2,
    "lead_investors": "Convergence Ventures"
  },
  {
    "date": "2016-11-22",
    "company_name": "Snapcart",
    "funding_name": "Non Equity Assistance",
    "funding_amount": 50000,
    "total_investors": 1,
    "lead_investors": "Google Launchpad Accelerator"
  },
  {
    "date": "2016-12-02",
    "company_name": "PAYFAZZ",
    "funding_name": "Pre Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Indigo by Telkom Indonesia"
  },
  {
    "date": "2017-01-01",
    "company_name": "Traveloka",
    "funding_name": "Venture Round",
    "funding_amount": 150000000,
    "total_investors": 4,
    "lead_investors": "unknown"
  },
  {
    "date": "2017-01-20",
    "company_name": "Sociolla",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "istyle"
  },
  {
    "date": "2017-01-31",
    "company_name": "HijUp",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2017-02-02",
    "company_name": "Cermati",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Finch Capital"
  },
  {
    "date": "2017-02-03",
    "company_name": "Deliveree Logistics",
    "funding_name": "Funding Round",
    "funding_amount": 2000000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2017-02-14",
    "company_name": "Moka",
    "funding_name": "Venture Round",
    "funding_amount": 2000000,
    "total_investors": 4,
    "lead_investors": "Mandiri Capital Indonesia (MCI)"
  },
  {
    "date": "2017-02-15",
    "company_name": "Akulaku",
    "funding_name": "Series A",
    "funding_amount": 11000000,
    "total_investors": 4,
    "lead_investors": "Arbor Ventures"
  },
  {
    "date": "2017-02-16",
    "company_name": "eFishery",
    "funding_name": "Non Equity Assistance",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2017-03-01",
    "company_name": "OY!",
    "funding_name": "Angel Round",
    "funding_amount": "unknown",
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2017-03-07",
    "company_name": "Amartha",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 5,
    "lead_investors": "Mandiri Capital Indonesia (MCI)"
  },
  {
    "date": "2017-03-14",
    "company_name": "PrivyID",
    "funding_name": "Seed Round",
    "funding_amount": 500000,
    "total_investors": 4,
    "lead_investors": "Mandiri Capital Indonesia (MCI), MDI Ventures"
  },
  {
    "date": "2017-03-27",
    "company_name": "Akulaku",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2017-03-27",
    "company_name": "Snapcart",
    "funding_name": "Seed Round",
    "funding_amount": 3000000,
    "total_investors": 3,
    "lead_investors": "Vickers Venture Partners"
  },
  {
    "date": "2017-04-03",
    "company_name": "Ayoconnect",
    "funding_name": "Seed Round",
    "funding_amount": 1000000,
    "total_investors": 3,
    "lead_investors": "STRIVE"
  },
  {
    "date": "2017-04-07",
    "company_name": "Aruna",
    "funding_name": "Pre Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "UMG Indonesia"
  },
  {
    "date": "2017-04-25",
    "company_name": "Stockbit",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "500 Global"
  },
  {
    "date": "2017-04-27",
    "company_name": "Ruangguru",
    "funding_name": "Grant",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "GSMA Ecosystem Accelerator"
  },
  {
    "date": "2017-05-15",
    "company_name": "CoHive",
    "funding_name": "Seed Round",
    "funding_amount": 800000,
    "total_investors": 3,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2017-05-23",
    "company_name": "SIRCLO",
    "funding_name": "Non Equity Assistance",
    "funding_amount": 50000,
    "total_investors": 1,
    "lead_investors": "Google Launchpad Accelerator"
  },
  {
    "date": "2017-06-01",
    "company_name": "Sicepat Express",
    "funding_name": "Seed Round",
    "funding_amount": 3500000,
    "total_investors": 1,
    "lead_investors": "Kejora Capital"
  },
  {
    "date": "2017-07-04",
    "company_name": "Ruangguru",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 3,
    "lead_investors": "UOB Venture"
  },
  {
    "date": "2017-07-06",
    "company_name": "Akulaku",
    "funding_name": "Series B",
    "funding_amount": 24800000,
    "total_investors": 4,
    "lead_investors": "Qiming Venture Partners"
  },
  {
    "date": "2017-07-19",
    "company_name": "JULO",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 3,
    "lead_investors": "East Ventures, Skystar Capital"
  },
  {
    "date": "2017-07-27",
    "company_name": "Traveloka",
    "funding_name": "Funding Round",
    "funding_amount": 350000000,
    "total_investors": 1,
    "lead_investors": "Expedia"
  },
  {
    "date": "2017-08-04",
    "company_name": "Gojek",
    "funding_name": "Series E",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2017-08-16",
    "company_name": "Sorabel",
    "funding_name": "Series B",
    "funding_amount": 27000000,
    "total_investors": 7,
    "lead_investors": "Gobi Partners, Golden Equator Ventures"
  },
  {
    "date": "2017-08-17",
    "company_name": "Tokopedia",
    "funding_name": "Series F",
    "funding_amount": 1100000000,
    "total_investors": 1,
    "lead_investors": "Alibaba Group"
  },
  {
    "date": "2017-08-21",
    "company_name": "PAYFAZZ",
    "funding_name": "Seed Round",
    "funding_amount": 120000,
    "total_investors": 3,
    "lead_investors": "unknown"
  },
  {
    "date": "2017-09-01",
    "company_name": "PAYFAZZ",
    "funding_name": "Convertible Note",
    "funding_amount": "unknown",
    "total_investors": 7,
    "lead_investors": "Insignia Ventures Partners"
  },
  {
    "date": "2017-09-08",
    "company_name": "Tokopedia",
    "funding_name": "Venture Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2017-09-08",
    "company_name": "GoWork",
    "funding_name": "Seed Round",
    "funding_amount": 3000000,
    "total_investors": 6,
    "lead_investors": "ATM Capital, Convergence Ventures"
  },
  {
    "date": "2017-09-14",
    "company_name": "CoHive",
    "funding_name": "Seed Round",
    "funding_amount": 3500000,
    "total_investors": 2,
    "lead_investors": "Insignia Ventures Partners"
  },
  {
    "date": "2017-10-01",
    "company_name": "OY!",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2017-10-04",
    "company_name": "FinAccel",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "Openspace"
  },
  {
    "date": "2017-10-09",
    "company_name": "Deliveree Logistics",
    "funding_name": "Series A",
    "funding_amount": 14500000,
    "total_investors": 6,
    "lead_investors": "Gobi Partners"
  },
  {
    "date": "2017-10-25",
    "company_name": "Snapcart",
    "funding_name": "Series A",
    "funding_amount": 10000000,
    "total_investors": 6,
    "lead_investors": "Vickers Venture Partners"
  },
  {
    "date": "2017-11-09",
    "company_name": "OnlinePajak",
    "funding_name": "Series A",
    "funding_amount": 3500000,
    "total_investors": 2,
    "lead_investors": "Alpha JWC Ventures"
  },
  {
    "date": "2017-11-10",
    "company_name": "Alodokter",
    "funding_name": "Series B",
    "funding_amount": 9000000,
    "total_investors": 1,
    "lead_investors": "Softbank Ventures Asia"
  },
  {
    "date": "2017-11-17",
    "company_name": "Bukalapak",
    "funding_name": "Series C",
    "funding_amount": "unknown",
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2017-11-23",
    "company_name": "INDODAX",
    "funding_name": "Venture Round",
    "funding_amount": 1000000,
    "total_investors": 1,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2017-11-28",
    "company_name": "Ayoconnect",
    "funding_name": "Series A",
    "funding_amount": 4000000,
    "total_investors": 3,
    "lead_investors": "Finch Capital"
  },
  {
    "date": "2017-12-31",
    "company_name": "Sorabel",
    "funding_name": "Venture Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2018-01-01",
    "company_name": "Flip",
    "funding_name": "Series A",
    "funding_amount": 17000000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2018-01-01",
    "company_name": "PasarPolis",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2018-02-12",
    "company_name": "Gojek",
    "funding_name": "Series E",
    "funding_amount": 1500000000,
    "total_investors": 11,
    "lead_investors": "Tencent"
  },
  {
    "date": "2018-02-12",
    "company_name": "Warung Pintar",
    "funding_name": "Seed Round",
    "funding_amount": 4000000,
    "total_investors": 5,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2018-02-20",
    "company_name": "waresix",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2018-02-26",
    "company_name": "eFishery",
    "funding_name": "Grant",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "GSMA Ecosystem Accelerator"
  },
  {
    "date": "2018-03-01",
    "company_name": "OY!",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2018-03-01",
    "company_name": "Fabelio",
    "funding_name": "Series B",
    "funding_amount": 6500000,
    "total_investors": 2,
    "lead_investors": "Aavishkaar Venture Capital"
  },
  {
    "date": "2018-03-12",
    "company_name": "Populix",
    "funding_name": "Angel Round",
    "funding_amount": "unknown",
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2018-04-25",
    "company_name": "TaniHub",
    "funding_name": "Seed Round",
    "funding_amount": 2000000,
    "total_investors": 1,
    "lead_investors": "Alpha JWC Ventures"
  },
  {
    "date": "2018-05-08",
    "company_name": "Travelio",
    "funding_name": "Series A",
    "funding_amount": 4000000,
    "total_investors": 5,
    "lead_investors": "Vynn Capital"
  },
  {
    "date": "2018-05-10",
    "company_name": "JULO",
    "funding_name": "Series A",
    "funding_amount": 5000000,
    "total_investors": 7,
    "lead_investors": "East Ventures, Skystar Capital"
  },
  {
    "date": "2018-05-15",
    "company_name": "GoWork",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2018-05-17",
    "company_name": "Sociolla",
    "funding_name": "Series C",
    "funding_amount": 12000000,
    "total_investors": 1,
    "lead_investors": "EV Growth"
  },
  {
    "date": "2018-05-21",
    "company_name": "Ruangguru",
    "funding_name": "Grant",
    "funding_amount": "unknown",
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2018-06-04",
    "company_name": "Xendit",
    "funding_name": "Series A",
    "funding_amount": 20000000,
    "total_investors": 3,
    "lead_investors": "Kleiner Perkins"
  },
  {
    "date": "2018-06-05",
    "company_name": "CoHive",
    "funding_name": "Series A",
    "funding_amount": 20000000,
    "total_investors": 14,
    "lead_investors": "Softbank Ventures Asia"
  },
  {
    "date": "2018-07-03",
    "company_name": "Populix",
    "funding_name": "Pre Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Pegasus Tech Ventures"
  },
  {
    "date": "2018-07-12",
    "company_name": "Moladin",
    "funding_name": "Seed Round",
    "funding_amount": 1200000,
    "total_investors": 5,
    "lead_investors": "East Ventures, Ethos Partners"
  },
  {
    "date": "2018-07-16",
    "company_name": "Bizzy",
    "funding_name": "Series A",
    "funding_amount": 8000000,
    "total_investors": 3,
    "lead_investors": "Sinarmas"
  },
  {
    "date": "2018-07-24",
    "company_name": "FinAccel",
    "funding_name": "Series B",
    "funding_amount": 30000000,
    "total_investors": 8,
    "lead_investors": "Square Peg Capital"
  },
  {
    "date": "2018-07-31",
    "company_name": "Investree",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 7,
    "lead_investors": "SBI Group"
  },
  {
    "date": "2018-08-01",
    "company_name": "Moladin",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2018-08-07",
    "company_name": "Warung Pintar",
    "funding_name": "Seed Round",
    "funding_amount": 4000000,
    "total_investors": 9,
    "lead_investors": "SMDV"
  },
  {
    "date": "2018-08-10",
    "company_name": "PasarPolis",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 3,
    "lead_investors": "Gojek, Traveloka"
  },
  {
    "date": "2018-08-20",
    "company_name": "Lemonilo",
    "funding_name": "Venture Round",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "Alpha JWC Ventures, Unifam Capital"
  },
  {
    "date": "2018-08-22",
    "company_name": "Ajaib",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2018-08-22",
    "company_name": "KoinWorks",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 4,
    "lead_investors": "Mandiri Capital Indonesia (MCI)"
  },
  {
    "date": "2018-08-31",
    "company_name": "GoWork",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2018-09-01",
    "company_name": "Sampingan",
    "funding_name": "Pre Seed Round",
    "funding_amount": 100000,
    "total_investors": 1,
    "lead_investors": "Antler"
  },
  {
    "date": "2018-09-01",
    "company_name": "Xurya",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2018-09-06",
    "company_name": "Cermati",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "PT. Djarum"
  },
  {
    "date": "2018-09-10",
    "company_name": "Ritase",
    "funding_name": "Seed Round",
    "funding_amount": 3000000,
    "total_investors": 3,
    "lead_investors": "Insignia Ventures Partners"
  },
  {
    "date": "2018-09-13",
    "company_name": "Moka",
    "funding_name": "Series B",
    "funding_amount": 24000000,
    "total_investors": 6,
    "lead_investors": "EV Growth, Sequoia Capital India"
  },
  {
    "date": "2018-09-17",
    "company_name": "Ralali",
    "funding_name": "Series B",
    "funding_amount": 7000000,
    "total_investors": 3,
    "lead_investors": "Digital Garage (TSE: 4819), SBI Group"
  },
  {
    "date": "2018-09-20",
    "company_name": "Fore Coffee",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2018-10-01",
    "company_name": "Mekari",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 4,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2018-10-09",
    "company_name": "waresix",
    "funding_name": "Seed Round",
    "funding_amount": 1600000,
    "total_investors": 4,
    "lead_investors": "East Ventures, Monk’s Hill Ventures"
  },
  {
    "date": "2018-10-10",
    "company_name": "GoWork",
    "funding_name": "Series A",
    "funding_amount": 10000000,
    "total_investors": 2,
    "lead_investors": "Gobi Partners, The Paradise Group"
  },
  {
    "date": "2018-10-11",
    "company_name": "Dekoruma",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "unknown"
  },
  {
    "date": "2018-10-14",
    "company_name": "Eden Farm Indonesia",
    "funding_name": "Pre Seed Round",
    "funding_amount": 20000,
    "total_investors": 1,
    "lead_investors": "EverHaüs"
  },
  {
    "date": "2018-10-18",
    "company_name": "Kopi Kenangan",
    "funding_name": "Seed Round",
    "funding_amount": 8000000,
    "total_investors": 1,
    "lead_investors": "Alpha JWC Ventures"
  },
  {
    "date": "2018-10-23",
    "company_name": "OnlinePajak",
    "funding_name": "Series B",
    "funding_amount": 25500000,
    "total_investors": 6,
    "lead_investors": "Warburg Pincus"
  },
  {
    "date": "2018-10-25",
    "company_name": "Qraved",
    "funding_name": "Series C",
    "funding_amount": "unknown",
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2018-10-30",
    "company_name": "Gojek",
    "funding_name": "Series F",
    "funding_amount": 920000000,
    "total_investors": 3,
    "lead_investors": "Google, JD.com, Tencent"
  },
  {
    "date": "2018-10-30",
    "company_name": "Akulaku",
    "funding_name": "Series C",
    "funding_amount": 69500000,
    "total_investors": 8,
    "lead_investors": "Sequoia Capital India"
  },
  {
    "date": "2018-11-12",
    "company_name": "AwanTunai",
    "funding_name": "Series A",
    "funding_amount": 4300000,
    "total_investors": 4,
    "lead_investors": "AMTD Group, Insignia Ventures Partners"
  },
  {
    "date": "2018-11-13",
    "company_name": "eFishery",
    "funding_name": "Series A",
    "funding_amount": 4000000,
    "total_investors": 7,
    "lead_investors": "unknown"
  },
  {
    "date": "2018-11-21",
    "company_name": "Tokopedia",
    "funding_name": "Series G",
    "funding_amount": 1100000000,
    "total_investors": 3,
    "lead_investors": "Alibaba Group, SoftBank Vision Fund"
  },
  {
    "date": "2018-11-21",
    "company_name": "PAYFAZZ",
    "funding_name": "Series A",
    "funding_amount": 21000000,
    "total_investors": 7,
    "lead_investors": "Tiger Global Management"
  },
  {
    "date": "2018-12-21",
    "company_name": "Cakap",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "InvestIdea Ventures"
  },
  {
    "date": "2019-01-01",
    "company_name": "eFishery",
    "funding_name": "Venture Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "InnoVen Capital"
  },
  {
    "date": "2019-01-07",
    "company_name": "Ajaib",
    "funding_name": "Seed Round",
    "funding_amount": 2100000,
    "total_investors": 4,
    "lead_investors": "Softbank Ventures Asia, Y Combinator"
  },
  {
    "date": "2019-01-10",
    "company_name": "Akulaku",
    "funding_name": "Series D",
    "funding_amount": 100000000,
    "total_investors": 1,
    "lead_investors": "Ant Group"
  },
  {
    "date": "2019-01-10",
    "company_name": "Sampingan",
    "funding_name": "Seed Round",
    "funding_amount": 500000,
    "total_investors": 1,
    "lead_investors": "Golden Gate Ventures"
  },
  {
    "date": "2019-01-17",
    "company_name": "Bukalapak",
    "funding_name": "Series E",
    "funding_amount": 50000000,
    "total_investors": 6,
    "lead_investors": "Mirae Asset-Naver Asia Growth Fund"
  },
  {
    "date": "2019-01-21",
    "company_name": "Warung Pintar",
    "funding_name": "Series A",
    "funding_amount": 27500000,
    "total_investors": 10,
    "lead_investors": "unknown"
  },
  {
    "date": "2019-01-30",
    "company_name": "Gojek",
    "funding_name": "Series F",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2019-01-31",
    "company_name": "Fore Coffee",
    "funding_name": "Series A",
    "funding_amount": 9500000,
    "total_investors": 7,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2019-02-08",
    "company_name": "Ritase",
    "funding_name": "Corporate Round",
    "funding_amount": 1600000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2019-03-04",
    "company_name": "Gojek",
    "funding_name": "Series F",
    "funding_amount": 100000000,
    "total_investors": 1,
    "lead_investors": "PT. Astra International Tbk"
  },
  {
    "date": "2019-03-04",
    "company_name": "Bobobox Indonesia",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "Alpha JWC Ventures"
  },
  {
    "date": "2019-03-04",
    "company_name": "Halodoc",
    "funding_name": "Series B",
    "funding_amount": 65000000,
    "total_investors": 4,
    "lead_investors": "UOB Venture"
  },
  {
    "date": "2019-03-18",
    "company_name": "Shipper",
    "funding_name": "Pre Seed Round",
    "funding_amount": 150000,
    "total_investors": 1,
    "lead_investors": "Y Combinator"
  },
  {
    "date": "2019-03-20",
    "company_name": "Kargo Technologies",
    "funding_name": "Seed Round",
    "funding_amount": 7600000,
    "total_investors": 8,
    "lead_investors": "Sequoia Capital India"
  },
  {
    "date": "2019-03-29",
    "company_name": "SIRCLO",
    "funding_name": "Series A",
    "funding_amount": 5000000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2019-04-01",
    "company_name": "Pintek",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Finch Capital"
  },
  {
    "date": "2019-04-11",
    "company_name": "Traveloka",
    "funding_name": "Private Equity Round",
    "funding_amount": 420000000,
    "total_investors": 2,
    "lead_investors": "GIC"
  },
  {
    "date": "2019-04-19",
    "company_name": "Bobobox Indonesia",
    "funding_name": "Seed Round",
    "funding_amount": 1500000,
    "total_investors": 3,
    "lead_investors": "Surge"
  },
  {
    "date": "2019-04-22",
    "company_name": "HappyFresh",
    "funding_name": "Series C",
    "funding_amount": 20000000,
    "total_investors": 9,
    "lead_investors": "Mirae Asset-Naver Asia Growth Fund, Naver"
  },
  {
    "date": "2019-04-23",
    "company_name": "Sicepat Express",
    "funding_name": "Series A",
    "funding_amount": 50000000,
    "total_investors": 2,
    "lead_investors": "Barito Teknologi, Kejora Capital"
  },
  {
    "date": "2019-04-25",
    "company_name": "LinkAja",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2019-05-06",
    "company_name": "Ritase",
    "funding_name": "Series A",
    "funding_amount": 8500000,
    "total_investors": 6,
    "lead_investors": "Golden Gate Ventures"
  },
  {
    "date": "2019-05-07",
    "company_name": "Stockbit",
    "funding_name": "Series A",
    "funding_amount": 4000000,
    "total_investors": 5,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2019-05-07",
    "company_name": "ALAMI",
    "funding_name": "Pre Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "tryb Group"
  },
  {
    "date": "2019-05-10",
    "company_name": "Sorabel",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 4,
    "lead_investors": "Kejora Capital"
  },
  {
    "date": "2019-05-13",
    "company_name": "Snapcart",
    "funding_name": "Venture Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2019-05-14",
    "company_name": "Advotics",
    "funding_name": "Seed Round",
    "funding_amount": 2700000,
    "total_investors": 1,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2019-05-24",
    "company_name": "GoWork",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2019-05-27",
    "company_name": "TaniHub",
    "funding_name": "Series A",
    "funding_amount": 10000000,
    "total_investors": 4,
    "lead_investors": "Openspace"
  },
  {
    "date": "2019-06-19",
    "company_name": "CoHive",
    "funding_name": "Series B",
    "funding_amount": 13500000,
    "total_investors": 1,
    "lead_investors": "Stonebridge Ventures"
  },
  {
    "date": "2019-06-25",
    "company_name": "KoinWorks",
    "funding_name": "Series B",
    "funding_amount": 12000000,
    "total_investors": 6,
    "lead_investors": "EV Growth, Quona Capital"
  },
  {
    "date": "2019-06-25",
    "company_name": "Kopi Kenangan",
    "funding_name": "Series A",
    "funding_amount": 20000000,
    "total_investors": 1,
    "lead_investors": "Sequoia Capital India"
  },
  {
    "date": "2019-06-25",
    "company_name": "Kopi Kenangan",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2019-07-01",
    "company_name": "Zenius Education",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2019-07-05",
    "company_name": "waresix",
    "funding_name": "Series A",
    "funding_amount": 14500000,
    "total_investors": 3,
    "lead_investors": "EV Growth"
  },
  {
    "date": "2019-07-15",
    "company_name": "Sayurbox",
    "funding_name": "Seed Round",
    "funding_amount": 650000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2019-07-23",
    "company_name": "Wahyoo",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 6,
    "lead_investors": "unknown"
  },
  {
    "date": "2019-07-25",
    "company_name": "Halodoc",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 3,
    "lead_investors": "Allianz X"
  },
  {
    "date": "2019-07-26",
    "company_name": "Ruangguru",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "unknown"
  },
  {
    "date": "2019-07-26",
    "company_name": "Ralali",
    "funding_name": "Series C",
    "funding_amount": 13000000,
    "total_investors": 5,
    "lead_investors": "Arbor Ventures, Jo Hirao, TNB AURA"
  },
  {
    "date": "2019-08-01",
    "company_name": "Sorabel",
    "funding_name": "Debt Financing",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "InnoVen Capital"
  },
  {
    "date": "2019-08-15",
    "company_name": "Logisly",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 3,
    "lead_investors": "Convergence Ventures, Genesia Ventures, SeedPlus"
  },
  {
    "date": "2019-09-02",
    "company_name": "Sociolla",
    "funding_name": "Series D",
    "funding_amount": 40000000,
    "total_investors": 2,
    "lead_investors": "EV Growth, Temasek Holdings"
  },
  {
    "date": "2019-09-03",
    "company_name": "Eden Farm Indonesia",
    "funding_name": "Seed Round",
    "funding_amount": 1700000,
    "total_investors": 5,
    "lead_investors": "Global Founders Capital"
  },
  {
    "date": "2019-09-04",
    "company_name": "Kredivo",
    "funding_name": "Debt Financing",
    "funding_amount": 20000000,
    "total_investors": 1,
    "lead_investors": "Partners for Growth"
  },
  {
    "date": "2019-09-10",
    "company_name": "Paxel.co",
    "funding_name": "Seed Round",
    "funding_amount": 10000000,
    "total_investors": 1,
    "lead_investors": "Johari Zein"
  },
  {
    "date": "2019-09-11",
    "company_name": "Pluang",
    "funding_name": "Series A",
    "funding_amount": 3000000,
    "total_investors": 1,
    "lead_investors": "Go-Ventures"
  },
  {
    "date": "2019-09-18",
    "company_name": "JULO",
    "funding_name": "Series A",
    "funding_amount": 10000000,
    "total_investors": 6,
    "lead_investors": "Quona Capital"
  },
  {
    "date": "2019-09-18",
    "company_name": "GoWork",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2019-09-19",
    "company_name": "Shipper",
    "funding_name": "Seed Round",
    "funding_amount": 5000000,
    "total_investors": 5,
    "lead_investors": "unknown"
  },
  {
    "date": "2019-09-30",
    "company_name": "Populix",
    "funding_name": "Seed Round",
    "funding_amount": 1000000,
    "total_investors": 3,
    "lead_investors": "Intudo Ventures"
  },
  {
    "date": "2019-10-04",
    "company_name": "Bukalapak",
    "funding_name": "Series F",
    "funding_amount": "unknown",
    "total_investors": 3,
    "lead_investors": "Shinhan Financial Group"
  },
  {
    "date": "2019-10-04",
    "company_name": "Paxel.co",
    "funding_name": "Series A",
    "funding_amount": 7000000,
    "total_investors": 3,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2019-10-05",
    "company_name": "Petskita",
    "funding_name": "Pre Seed Round",
    "funding_amount": 50000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2019-10-06",
    "company_name": "Aruna",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "East Ventures, SMDV"
  },
  {
    "date": "2019-10-09",
    "company_name": "Sampingan",
    "funding_name": "Seed Round",
    "funding_amount": 1500000,
    "total_investors": 2,
    "lead_investors": "Golden Gate Ventures"
  },
  {
    "date": "2019-10-10",
    "company_name": "Chilibeli",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 4,
    "lead_investors": "Surge"
  },
  {
    "date": "2019-10-15",
    "company_name": "Alodokter",
    "funding_name": "Series C",
    "funding_amount": 33000000,
    "total_investors": 7,
    "lead_investors": "Sequis Life"
  },
  {
    "date": "2019-10-17",
    "company_name": "Zenius Education",
    "funding_name": "Series A",
    "funding_amount": 20000000,
    "total_investors": 3,
    "lead_investors": "Northstar Group"
  },
  {
    "date": "2019-10-17",
    "company_name": "Yummy Corp",
    "funding_name": "Series A",
    "funding_amount": 7800000,
    "total_investors": 7,
    "lead_investors": "Intudo Ventures, SMDV"
  },
  {
    "date": "2019-11-04",
    "company_name": "KoinWorks",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Saison Capital"
  },
  {
    "date": "2019-11-06",
    "company_name": "Fore Coffee",
    "funding_name": "Venture Round",
    "funding_amount": 30000000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2019-11-08",
    "company_name": "Pintek",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 3,
    "lead_investors": "Global Founders Capital"
  },
  {
    "date": "2019-11-13",
    "company_name": "ALAMI",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 3,
    "lead_investors": "Golden Gate Ventures"
  },
  {
    "date": "2019-11-14",
    "company_name": "Travelio",
    "funding_name": "Series B",
    "funding_amount": 18000000,
    "total_investors": 8,
    "lead_investors": "Gobi Partners, Pavilion Capital"
  },
  {
    "date": "2019-11-27",
    "company_name": "Amartha",
    "funding_name": "Series B",
    "funding_amount": 18000000,
    "total_investors": 3,
    "lead_investors": "LINE Ventures"
  },
  {
    "date": "2019-12-01",
    "company_name": "Mekari",
    "funding_name": "Series C",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2019-12-01",
    "company_name": "Mekari",
    "funding_name": "Series C",
    "funding_amount": "unknown",
    "total_investors": 3,
    "lead_investors": "EV Growth"
  },
  {
    "date": "2019-12-02",
    "company_name": "FinAccel",
    "funding_name": "Series C",
    "funding_amount": 90000000,
    "total_investors": 11,
    "lead_investors": "MDI Ventures, South Asia Growth Fund II (SAGFII), Square Peg Capital, Telkomsel Mitra Inovasi"
  },
  {
    "date": "2019-12-03",
    "company_name": "Komunal",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2019-12-04",
    "company_name": "Modalku",
    "funding_name": "Debt Financing",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Triodos Investment Management"
  },
  {
    "date": "2019-12-06",
    "company_name": "Evermos",
    "funding_name": "Series A",
    "funding_amount": 8300000,
    "total_investors": 3,
    "lead_investors": "Jungle Ventures"
  },
  {
    "date": "2019-12-10",
    "company_name": "DIKA",
    "funding_name": "Pre Seed Round",
    "funding_amount": 65000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2019-12-10",
    "company_name": "Pinhome",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "unknown"
  },
  {
    "date": "2019-12-12",
    "company_name": "Bukalapak",
    "funding_name": "Venture Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "500 Global"
  },
  {
    "date": "2019-12-23",
    "company_name": "Kopi Kenangan",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 4,
    "lead_investors": "unknown"
  },
  {
    "date": "2019-12-26",
    "company_name": "Ruangguru",
    "funding_name": "Series C",
    "funding_amount": 150000000,
    "total_investors": 4,
    "lead_investors": "General Atlantic, GGV Capital"
  },
  {
    "date": "2020-01-01",
    "company_name": "CoLearn",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "unknown"
  },
  {
    "date": "2020-01-10",
    "company_name": "PrivyID",
    "funding_name": "Series A",
    "funding_amount": 5600000,
    "total_investors": 5,
    "lead_investors": "Mandiri Capital Indonesia (MCI), MDI Ventures, Telkomsel Mitra Inovasi"
  },
  {
    "date": "2020-01-14",
    "company_name": "waresix",
    "funding_name": "Series A",
    "funding_amount": 11000000,
    "total_investors": 2,
    "lead_investors": "EV Growth, Jungle Ventures"
  },
  {
    "date": "2020-01-16",
    "company_name": "Moladin",
    "funding_name": "Pre Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2020-01-21",
    "company_name": "Hangry",
    "funding_name": "Seed Round",
    "funding_amount": 2000000,
    "total_investors": 2,
    "lead_investors": "Surge"
  },
  {
    "date": "2020-01-28",
    "company_name": "Kredivo",
    "funding_name": "Funding Round",
    "funding_amount": 90000000,
    "total_investors": 2,
    "lead_investors": "Reinventure"
  },
  {
    "date": "2020-01-30",
    "company_name": "OY!",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 4,
    "lead_investors": "MDI Ventures, Wavemaker Partners"
  },
  {
    "date": "2020-02-05",
    "company_name": "AGretail.id",
    "funding_name": "Pre Seed Round",
    "funding_amount": 200000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2020-02-10",
    "company_name": "GudangAda",
    "funding_name": "Seed Round",
    "funding_amount": 10500000,
    "total_investors": 3,
    "lead_investors": "Alpha JWC Ventures"
  },
  {
    "date": "2020-02-18",
    "company_name": "Eureka",
    "funding_name": "Series B",
    "funding_amount": 20000000,
    "total_investors": 7,
    "lead_investors": "Apis Partners, Gobi Partners, Riyad TAQNIA Fund (RTF)"
  },
  {
    "date": "2020-02-27",
    "company_name": "Stockbit",
    "funding_name": "Series B",
    "funding_amount": 10000000,
    "total_investors": 5,
    "lead_investors": "unknown"
  },
  {
    "date": "2020-03-01",
    "company_name": "Snapcart",
    "funding_name": "Venture Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2020-03-10",
    "company_name": "Gojek",
    "funding_name": "Series F",
    "funding_amount": 1200000000,
    "total_investors": 5,
    "lead_investors": "Mitsubishi Corporation, Mitsubishi Motors, Mitsubishi UFJ Financial Group, Visa"
  },
  {
    "date": "2020-03-19",
    "company_name": "Investree",
    "funding_name": "Series C",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2020-03-20",
    "company_name": "Logisly",
    "funding_name": "Series A",
    "funding_amount": 6000000,
    "total_investors": 1,
    "lead_investors": "Monk’s Hill Ventures"
  },
  {
    "date": "2020-03-20",
    "company_name": "Chilibeli",
    "funding_name": "Series A",
    "funding_amount": 10000000,
    "total_investors": 6,
    "lead_investors": "Lightspeed Venture Partners"
  },
  {
    "date": "2020-03-20",
    "company_name": "Nusantics",
    "funding_name": "Seed Round",
    "funding_amount": 700000,
    "total_investors": 1,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2020-03-27",
    "company_name": "Kargo Technologies",
    "funding_name": "Corporate Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Amatil X Ventures"
  },
  {
    "date": "2020-03-30",
    "company_name": "Sicepat Express",
    "funding_name": "Corporate Round",
    "funding_amount": "unknown",
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2020-04-01",
    "company_name": "TaniHub",
    "funding_name": "Series A",
    "funding_amount": 17000000,
    "total_investors": 7,
    "lead_investors": "Intudo Ventures, Openspace"
  },
  {
    "date": "2020-04-01",
    "company_name": "LingoTalk",
    "funding_name": "Angel Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2020-04-07",
    "company_name": "BukuWarung",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 5,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2020-04-08",
    "company_name": "Investree",
    "funding_name": "Series C",
    "funding_amount": 24000000,
    "total_investors": 2,
    "lead_investors": "BRI Ventures, MUFG Innovation Partners"
  },
  {
    "date": "2020-04-13",
    "company_name": "KoinWorks",
    "funding_name": "Debt Financing",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Triodos Bank Germany"
  },
  {
    "date": "2020-04-13",
    "company_name": "KoinWorks",
    "funding_name": "Venture Round",
    "funding_amount": 20000000,
    "total_investors": 4,
    "lead_investors": "Quona Capital"
  },
  {
    "date": "2020-04-13",
    "company_name": "Kargo Technologies",
    "funding_name": "Series A",
    "funding_amount": 31000000,
    "total_investors": 10,
    "lead_investors": "Tenaya Capital"
  },
  {
    "date": "2020-04-16",
    "company_name": "Deliveree Logistics",
    "funding_name": "Series B",
    "funding_amount": 18500000,
    "total_investors": 3,
    "lead_investors": "unknown"
  },
  {
    "date": "2020-05-05",
    "company_name": "Sayurbox",
    "funding_name": "Series A",
    "funding_amount": 5000000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2020-05-05",
    "company_name": "GudangAda",
    "funding_name": "Series A",
    "funding_amount": 25400000,
    "total_investors": 3,
    "lead_investors": "Alpha JWC Ventures, Sequoia Capital India"
  },
  {
    "date": "2020-05-12",
    "company_name": "Dekoruma",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 4,
    "lead_investors": "unknown"
  },
  {
    "date": "2020-05-12",
    "company_name": "Kopi Kenangan",
    "funding_name": "Series B",
    "funding_amount": 209000000,
    "total_investors": 10,
    "lead_investors": "Sequoia Capital India"
  },
  {
    "date": "2020-05-14",
    "company_name": "Pintek",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Accion Venture Lab"
  },
  {
    "date": "2020-05-20",
    "company_name": "Shipper",
    "funding_name": "Series A",
    "funding_amount": 20000000,
    "total_investors": 4,
    "lead_investors": "Naspers"
  },
  {
    "date": "2020-05-20",
    "company_name": "wagely",
    "funding_name": "Pre Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2020-05-21",
    "company_name": "KoinWorks",
    "funding_name": "Debt Financing",
    "funding_amount": 10000000,
    "total_investors": 1,
    "lead_investors": "Lendable"
  },
  {
    "date": "2020-05-21",
    "company_name": "Bobobox Indonesia",
    "funding_name": "Series A",
    "funding_amount": 11500000,
    "total_investors": 5,
    "lead_investors": "Alpha JWC Ventures, Horizons Ventures"
  },
  {
    "date": "2020-06-03",
    "company_name": "Gojek",
    "funding_name": "Series F",
    "funding_amount": 375000000,
    "total_investors": 5,
    "lead_investors": "Golden Gate Ventures, Meta, PayPal"
  },
  {
    "date": "2020-06-08",
    "company_name": "GoPlay",
    "funding_name": "Series A",
    "funding_amount": 15000000,
    "total_investors": 5,
    "lead_investors": "Golden Gate Ventures, ZWC Partners"
  },
  {
    "date": "2020-06-10",
    "company_name": "Ula",
    "funding_name": "Seed Round",
    "funding_amount": 10500000,
    "total_investors": 13,
    "lead_investors": "Lightspeed India Partners, Sequoia Capital India"
  },
  {
    "date": "2020-06-17",
    "company_name": "Fabelio",
    "funding_name": "Series C",
    "funding_amount": 9000000,
    "total_investors": 5,
    "lead_investors": "AppWorks"
  },
  {
    "date": "2020-07-06",
    "company_name": "PAYFAZZ",
    "funding_name": "Series B",
    "funding_amount": 53000000,
    "total_investors": 7,
    "lead_investors": "B Capital Group, Insignia Ventures Partners"
  },
  {
    "date": "2020-07-06",
    "company_name": "Sociolla",
    "funding_name": "Series E",
    "funding_amount": 58000000,
    "total_investors": 3,
    "lead_investors": "unknown"
  },
  {
    "date": "2020-07-08",
    "company_name": "BukuWarung",
    "funding_name": "Funding Round",
    "funding_amount": "unknown",
    "total_investors": 8,
    "lead_investors": "Quona Capital"
  },
  {
    "date": "2020-07-08",
    "company_name": "TiinTiin",
    "funding_name": "Seed Round",
    "funding_amount": 2500000,
    "total_investors": 3,
    "lead_investors": "Amand Ventures, Luminary Media Nusantara, Rolf Monteiro"
  },
  {
    "date": "2020-07-20",
    "company_name": "AwanTunai",
    "funding_name": "Debt Financing",
    "funding_amount": 20000000,
    "total_investors": 1,
    "lead_investors": "Accial Capital"
  },
  {
    "date": "2020-07-28",
    "company_name": "Traveloka",
    "funding_name": "Venture Round",
    "funding_amount": 250000000,
    "total_investors": 2,
    "lead_investors": "Qatar Investment Authority"
  },
  {
    "date": "2020-07-30",
    "company_name": "GoWork",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2020-08-05",
    "company_name": "Ayoconnect",
    "funding_name": "Series B",
    "funding_amount": 5000000,
    "total_investors": 8,
    "lead_investors": "unknown"
  },
  {
    "date": "2020-08-05",
    "company_name": "Wahyoo",
    "funding_name": "Series A",
    "funding_amount": 5000000,
    "total_investors": 8,
    "lead_investors": "Intudo Ventures"
  },
  {
    "date": "2020-08-11",
    "company_name": "eFishery",
    "funding_name": "Series B",
    "funding_amount": 15000000,
    "total_investors": 7,
    "lead_investors": "Go-Ventures, Northstar Group"
  },
  {
    "date": "2020-08-12",
    "company_name": "Aruna",
    "funding_name": "Series A",
    "funding_amount": 5500000,
    "total_investors": 5,
    "lead_investors": "unknown"
  },
  {
    "date": "2020-08-25",
    "company_name": "KitaBeli",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2020-08-28",
    "company_name": "SIRCLO",
    "funding_name": "Series B",
    "funding_amount": 6000000,
    "total_investors": 4,
    "lead_investors": "unknown"
  },
  {
    "date": "2020-09-02",
    "company_name": "PasarPolis",
    "funding_name": "Series B",
    "funding_amount": 54000000,
    "total_investors": 6,
    "lead_investors": "unknown"
  },
  {
    "date": "2020-09-08",
    "company_name": "waresix",
    "funding_name": "Series B",
    "funding_amount": 100000000,
    "total_investors": 6,
    "lead_investors": "EV Growth"
  },
  {
    "date": "2020-09-15",
    "company_name": "Xurya",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2020-09-20",
    "company_name": "Shooper",
    "funding_name": "Pre Seed Round",
    "funding_amount": 130000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2020-09-25",
    "company_name": "Yummy Corp",
    "funding_name": "Series B",
    "funding_amount": 12000000,
    "total_investors": 9,
    "lead_investors": "Softbank Ventures Asia"
  },
  {
    "date": "2020-09-28",
    "company_name": "BukuWarung",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 17,
    "lead_investors": "JAM Fund"
  },
  {
    "date": "2020-10-09",
    "company_name": "Paxel.co",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2020-11-04",
    "company_name": "Bukalapak",
    "funding_name": "Series G",
    "funding_amount": 100000000,
    "total_investors": 3,
    "lead_investors": "unknown"
  },
  {
    "date": "2020-11-09",
    "company_name": "iSeller",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "Mandiri Capital Indonesia (MCI), Openspace"
  },
  {
    "date": "2020-11-10",
    "company_name": "LinkAja",
    "funding_name": "Series B",
    "funding_amount": 100000000,
    "total_investors": 4,
    "lead_investors": "Grab"
  },
  {
    "date": "2020-11-12",
    "company_name": "Alodokter",
    "funding_name": "Series C",
    "funding_amount": "unknown",
    "total_investors": 5,
    "lead_investors": "MDI Ventures"
  },
  {
    "date": "2020-11-16",
    "company_name": "Tokopedia",
    "funding_name": "Series H",
    "funding_amount": 350000000,
    "total_investors": 2,
    "lead_investors": "Google, Temasek Holdings"
  },
  {
    "date": "2020-11-17",
    "company_name": "Gojek",
    "funding_name": "Corporate Round",
    "funding_amount": 150000000,
    "total_investors": 1,
    "lead_investors": "Telkomsel"
  },
  {
    "date": "2020-11-23",
    "company_name": "Mangkokku",
    "funding_name": "Seed Round",
    "funding_amount": 2000000,
    "total_investors": 1,
    "lead_investors": "Alpha JWC Ventures"
  },
  {
    "date": "2020-11-24",
    "company_name": "Kredivo",
    "funding_name": "Debt Financing",
    "funding_amount": 100000000,
    "total_investors": 1,
    "lead_investors": "Victory Park Capital"
  },
  {
    "date": "2020-12-01",
    "company_name": "GajiGesa",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2020-12-01",
    "company_name": "Pintek",
    "funding_name": "Venture Round",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "Finch Capital"
  },
  {
    "date": "2020-12-04",
    "company_name": "Otoklix",
    "funding_name": "Seed Round",
    "funding_amount": 1866667,
    "total_investors": 6,
    "lead_investors": "Surge"
  },
  {
    "date": "2020-12-07",
    "company_name": "CoLearn",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 8,
    "lead_investors": "January Capital"
  },
  {
    "date": "2020-12-22",
    "company_name": "Sicepat Express",
    "funding_name": "Series B",
    "funding_amount": 50000000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2020-12-23",
    "company_name": "Cakap",
    "funding_name": "Series A",
    "funding_amount": 3000000,
    "total_investors": 4,
    "lead_investors": "Heritas Capital"
  },
  {
    "date": "2021-01-01",
    "company_name": "Evermos",
    "funding_name": "Non Equity Assistance",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Endeavor Indonesia"
  },
  {
    "date": "2021-01-01",
    "company_name": "Astro",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2021-01-05",
    "company_name": "Stockbit",
    "funding_name": "Series C",
    "funding_amount": 35000000,
    "total_investors": 6,
    "lead_investors": "Sequoia Capital India"
  },
  {
    "date": "2021-01-05",
    "company_name": "Bibit.id",
    "funding_name": "Series A",
    "funding_amount": 30000000,
    "total_investors": 5,
    "lead_investors": "Sequoia Capital India"
  },
  {
    "date": "2021-01-07",
    "company_name": "Zenius Education",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 5,
    "lead_investors": "unknown"
  },
  {
    "date": "2021-01-07",
    "company_name": "Nusantics",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2021-01-11",
    "company_name": "Ajaib",
    "funding_name": "Series A",
    "funding_amount": 25000000,
    "total_investors": 5,
    "lead_investors": "Alpha JWC Ventures, Horizons Ventures"
  },
  {
    "date": "2021-01-11",
    "company_name": "ALAMI",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 4,
    "lead_investors": "AC Ventures, Golden Gate Ventures"
  },
  {
    "date": "2021-01-11",
    "company_name": "ALAMI",
    "funding_name": "Debt Financing",
    "funding_amount": 20000000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2021-01-11",
    "company_name": "Pintek",
    "funding_name": "Debt Financing",
    "funding_amount": 21000000,
    "total_investors": 1,
    "lead_investors": "Accial Capital"
  },
  {
    "date": "2021-01-12",
    "company_name": "Bukukas",
    "funding_name": "Series A",
    "funding_amount": 10000000,
    "total_investors": 7,
    "lead_investors": "Sequoia Capital India"
  },
  {
    "date": "2021-01-12",
    "company_name": "Sampingan",
    "funding_name": "Series A",
    "funding_amount": 5000000,
    "total_investors": 3,
    "lead_investors": "Altara Ventures"
  },
  {
    "date": "2021-01-14",
    "company_name": "Bukalapak",
    "funding_name": "Series G",
    "funding_amount": "unknown",
    "total_investors": 3,
    "lead_investors": "Standard Chartered Bank"
  },
  {
    "date": "2021-01-19",
    "company_name": "LingoTalk",
    "funding_name": "Angel Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2021-01-28",
    "company_name": "Ula",
    "funding_name": "Series A",
    "funding_amount": 20000000,
    "total_investors": 5,
    "lead_investors": "B Capital Group, Quona Capital"
  },
  {
    "date": "2021-02-03",
    "company_name": "BukuWarung",
    "funding_name": "Venture Round",
    "funding_amount": 20000000,
    "total_investors": 2,
    "lead_investors": "Rocketship.vc"
  },
  {
    "date": "2021-02-04",
    "company_name": "PasarPolis",
    "funding_name": "Private Equity Round",
    "funding_amount": 5000000,
    "total_investors": 1,
    "lead_investors": "International Finance Corporation"
  },
  {
    "date": "2021-02-04",
    "company_name": "GajiGesa",
    "funding_name": "Seed Round",
    "funding_amount": 2500000,
    "total_investors": 6,
    "lead_investors": "Defy.vc, Quest Ventures"
  },
  {
    "date": "2021-02-09",
    "company_name": "waresix",
    "funding_name": "Series B",
    "funding_amount": 1900000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2021-02-24",
    "company_name": "Amartha",
    "funding_name": "Debt Financing",
    "funding_amount": 10000000,
    "total_investors": 1,
    "lead_investors": "Lendable"
  },
  {
    "date": "2021-02-26",
    "company_name": "Akulaku",
    "funding_name": "Series D",
    "funding_amount": 125000000,
    "total_investors": 1,
    "lead_investors": "The Silverhorn Group"
  },
  {
    "date": "2021-03-02",
    "company_name": "Advotics",
    "funding_name": "Venture Round",
    "funding_amount": 2800000,
    "total_investors": 1,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2021-03-03",
    "company_name": "Xendit",
    "funding_name": "Series B",
    "funding_amount": 64600000,
    "total_investors": 3,
    "lead_investors": "Accel"
  },
  {
    "date": "2021-03-03",
    "company_name": "Eden Farm Indonesia",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 5,
    "lead_investors": "Investible"
  },
  {
    "date": "2021-03-05",
    "company_name": "Sicepat Express",
    "funding_name": "Series B",
    "funding_amount": 170000000,
    "total_investors": 8,
    "lead_investors": "Falcon House Partners, MDI Ventures"
  },
  {
    "date": "2021-03-09",
    "company_name": "LinkAja",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Gojek"
  },
  {
    "date": "2021-03-22",
    "company_name": "Segari",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 3,
    "lead_investors": "BEENEXT"
  },
  {
    "date": "2021-03-22",
    "company_name": "Pluang",
    "funding_name": "Series B",
    "funding_amount": 20000000,
    "total_investors": 2,
    "lead_investors": "Openspace"
  },
  {
    "date": "2021-03-24",
    "company_name": "KitaBeli",
    "funding_name": "Series A",
    "funding_amount": 10000000,
    "total_investors": 4,
    "lead_investors": "Go-Ventures"
  },
  {
    "date": "2021-03-29",
    "company_name": "Ajaib",
    "funding_name": "Series A",
    "funding_amount": 65000000,
    "total_investors": 10,
    "lead_investors": "Ribbit Capital"
  },
  {
    "date": "2021-04-14",
    "company_name": "Bukalapak",
    "funding_name": "Venture Round",
    "funding_amount": 234000000,
    "total_investors": 6,
    "lead_investors": "Emtek Group, GIC, Microsoft"
  },
  {
    "date": "2021-04-15",
    "company_name": "Shipper",
    "funding_name": "Series B",
    "funding_amount": 63000000,
    "total_investors": 9,
    "lead_investors": "DST Global, Sequoia Capital India"
  },
  {
    "date": "2021-04-16",
    "company_name": "Ruangguru",
    "funding_name": "Venture Round",
    "funding_amount": 55000000,
    "total_investors": 2,
    "lead_investors": "Tiger Global Management"
  },
  {
    "date": "2021-04-20",
    "company_name": "CoLearn",
    "funding_name": "Series A",
    "funding_amount": 10000000,
    "total_investors": 5,
    "lead_investors": "Alpha Wave Incubation, GSV Ventures"
  },
  {
    "date": "2021-04-21",
    "company_name": "Sayurbox",
    "funding_name": "Series B",
    "funding_amount": 15000000,
    "total_investors": 2,
    "lead_investors": "PT. Astra International Tbk"
  },
  {
    "date": "2021-04-21",
    "company_name": "Halodoc",
    "funding_name": "Series C",
    "funding_amount": 80000000,
    "total_investors": 9,
    "lead_investors": "PT. Astra International Tbk"
  },
  {
    "date": "2021-04-22",
    "company_name": "Paxel.co",
    "funding_name": "Venture Round",
    "funding_amount": "unknown",
    "total_investors": 4,
    "lead_investors": "Centauri Fund, MDI Ventures"
  },
  {
    "date": "2021-04-22",
    "company_name": "waresix",
    "funding_name": "Venture Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "MDI Ventures"
  },
  {
    "date": "2021-04-28",
    "company_name": "Bukalapak",
    "funding_name": "Venture Round",
    "funding_amount": 400000000,
    "total_investors": 2,
    "lead_investors": "unknown"
  },
  {
    "date": "2021-04-29",
    "company_name": "Populix",
    "funding_name": "Venture Round",
    "funding_amount": 1200000,
    "total_investors": 2,
    "lead_investors": "Intudo Ventures"
  },
  {
    "date": "2021-05-02",
    "company_name": "Hangry",
    "funding_name": "Series A",
    "funding_amount": 13000000,
    "total_investors": 4,
    "lead_investors": "Alpha JWC Ventures"
  },
  {
    "date": "2021-05-03",
    "company_name": "Amartha",
    "funding_name": "Venture Round",
    "funding_amount": 28000000,
    "total_investors": 4,
    "lead_investors": "MDI Ventures, Women's World Banking"
  },
  {
    "date": "2021-05-03",
    "company_name": "Bibit.id",
    "funding_name": "Venture Round",
    "funding_amount": 65000000,
    "total_investors": 6,
    "lead_investors": "Sequoia Capital India"
  },
  {
    "date": "2021-05-04",
    "company_name": "Sociolla",
    "funding_name": "Venture Round",
    "funding_amount": 56000000,
    "total_investors": 4,
    "lead_investors": "L Catterton"
  },
  {
    "date": "2021-05-05",
    "company_name": "Cermati",
    "funding_name": "Series C",
    "funding_amount": "unknown",
    "total_investors": 4,
    "lead_investors": "MDI Ventures"
  },
  {
    "date": "2021-05-07",
    "company_name": "SIRCLO",
    "funding_name": "Series B",
    "funding_amount": 45000000,
    "total_investors": 2,
    "lead_investors": "SMDV"
  },
  {
    "date": "2021-05-07",
    "company_name": "GajiGesa",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2021-05-10",
    "company_name": "Gojek",
    "funding_name": "Corporate Round",
    "funding_amount": 300000000,
    "total_investors": 1,
    "lead_investors": "Telkomsel"
  },
  {
    "date": "2021-05-17",
    "company_name": "Mekari",
    "funding_name": "Series D",
    "funding_amount": 21000000,
    "total_investors": 3,
    "lead_investors": "Money Forward"
  },
  {
    "date": "2021-05-21",
    "company_name": "TaniHub",
    "funding_name": "Series B",
    "funding_amount": 65500000,
    "total_investors": 10,
    "lead_investors": "MDI Ventures"
  },
  {
    "date": "2021-05-25",
    "company_name": "Pintu",
    "funding_name": "Series A",
    "funding_amount": 6000000,
    "total_investors": 9,
    "lead_investors": "Coinbase Ventures, Intudo Ventures, Pantera Capital"
  },
  {
    "date": "2021-05-25",
    "company_name": "Lemonilo",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Sequoia Capital India"
  },
  {
    "date": "2021-06-03",
    "company_name": "Amartha",
    "funding_name": "Venture Round",
    "funding_amount": 7500000,
    "total_investors": 1,
    "lead_investors": "Norfund"
  },
  {
    "date": "2021-06-04",
    "company_name": "prixa.ai",
    "funding_name": "Seed Round",
    "funding_amount": 3000000,
    "total_investors": 3,
    "lead_investors": "MDI Ventures, Trans-Pacific Technology Fund (TPTF)"
  },
  {
    "date": "2021-06-07",
    "company_name": "Alodokter",
    "funding_name": "Venture Round",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "MDI Ventures"
  },
  {
    "date": "2021-06-09",
    "company_name": "wagely",
    "funding_name": "Seed Round",
    "funding_amount": 5600000,
    "total_investors": 7,
    "lead_investors": "Integra Partners"
  },
  {
    "date": "2021-06-10",
    "company_name": "BukuWarung",
    "funding_name": "Series A",
    "funding_amount": 60000000,
    "total_investors": 2,
    "lead_investors": "Goodwater Capital, Valar Ventures"
  },
  {
    "date": "2021-06-21",
    "company_name": "Kredivo",
    "funding_name": "Debt Financing",
    "funding_amount": 100000000,
    "total_investors": 1,
    "lead_investors": "Victory Park Capital"
  },
  {
    "date": "2021-07-01",
    "company_name": "Flip",
    "funding_name": "Non Equity Assistance",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Endeavor Indonesia"
  },
  {
    "date": "2021-07-01",
    "company_name": "Sampingan",
    "funding_name": "Non Equity Assistance",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Endeavor Indonesia"
  },
  {
    "date": "2021-07-01",
    "company_name": "Logisly",
    "funding_name": "Non Equity Assistance",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Endeavor Indonesia"
  },
  {
    "date": "2021-07-01",
    "company_name": "Otoklix",
    "funding_name": "Non Equity Assistance",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Endeavor Indonesia"
  },
  {
    "date": "2021-07-01",
    "company_name": "Xurya",
    "funding_name": "Non Equity Assistance",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Endeavor Indonesia"
  },
  {
    "date": "2021-07-07",
    "company_name": "Warung Pintar",
    "funding_name": "Series B",
    "funding_amount": 6000000,
    "total_investors": 2,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2021-07-12",
    "company_name": "OnlinePajak",
    "funding_name": "Series C",
    "funding_amount": 12000000,
    "total_investors": 6,
    "lead_investors": "Altos Ventures, Tencent"
  },
  {
    "date": "2021-07-12",
    "company_name": "OnlinePajak",
    "funding_name": "Secondary Market",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Tencent"
  },
  {
    "date": "2021-07-14",
    "company_name": "Xurya",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 3,
    "lead_investors": "unknown"
  },
  {
    "date": "2021-07-16",
    "company_name": "OY!",
    "funding_name": "Series A",
    "funding_amount": 44000000,
    "total_investors": 7,
    "lead_investors": "MDI Ventures, Softbank Ventures Asia"
  },
  {
    "date": "2021-07-21",
    "company_name": "GudangAda",
    "funding_name": "Series B",
    "funding_amount": 100000000,
    "total_investors": 5,
    "lead_investors": "Alpha Wave Global, Asia Partners Fund Management"
  },
  {
    "date": "2021-07-22",
    "company_name": "Aruna",
    "funding_name": "Series A",
    "funding_amount": 29500000,
    "total_investors": 8,
    "lead_investors": "East Ventures, Prosus Ventures"
  },
  {
    "date": "2021-07-23",
    "company_name": "Pinhome",
    "funding_name": "Series A",
    "funding_amount": 25500000,
    "total_investors": 5,
    "lead_investors": "Ribbit Capital"
  },
  {
    "date": "2021-07-27",
    "company_name": "HappyFresh",
    "funding_name": "Series D",
    "funding_amount": 65000000,
    "total_investors": 7,
    "lead_investors": "Gafina, Naver Financial Corp."
  },
  {
    "date": "2021-08-01",
    "company_name": "Aruna",
    "funding_name": "Series A",
    "funding_amount": 35000000,
    "total_investors": 7,
    "lead_investors": "unknown"
  },
  {
    "date": "2021-08-02",
    "company_name": "Yummy Corp",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "BRI Ventures"
  },
  {
    "date": "2021-08-05",
    "company_name": "Bukalapak",
    "funding_name": "Venture Round",
    "funding_amount": 93333,
    "total_investors": 1,
    "lead_investors": "GIC"
  },
  {
    "date": "2021-08-09",
    "company_name": "Pintu",
    "funding_name": "Series A",
    "funding_amount": 35000000,
    "total_investors": 8,
    "lead_investors": "Lightspeed Venture Partners"
  },
  {
    "date": "2021-08-12",
    "company_name": "ALAMI",
    "funding_name": "Series A",
    "funding_amount": 17500000,
    "total_investors": 5,
    "lead_investors": "EV Growth, Quona Capital"
  },
  {
    "date": "2021-08-17",
    "company_name": "LingoTalk",
    "funding_name": "Pre Seed Round",
    "funding_amount": "unknown",
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2021-08-19",
    "company_name": "Zi.Care",
    "funding_name": "Seed Round",
    "funding_amount": 500000,
    "total_investors": 3,
    "lead_investors": "unknown"
  },
  {
    "date": "2021-08-24",
    "company_name": "Dekoruma",
    "funding_name": "Series C",
    "funding_amount": 15000000,
    "total_investors": 5,
    "lead_investors": "KTB Network, Nexter Ventures"
  },
  {
    "date": "2021-08-27",
    "company_name": "AwanTunai",
    "funding_name": "Series A",
    "funding_amount": 11200000,
    "total_investors": 4,
    "lead_investors": "Insignia Ventures Partners"
  },
  {
    "date": "2021-08-27",
    "company_name": "AwanTunai",
    "funding_name": "Debt Financing",
    "funding_amount": 45000000,
    "total_investors": 2,
    "lead_investors": "unknown"
  },
  {
    "date": "2021-09-01",
    "company_name": "Ayoconnect",
    "funding_name": "Series B",
    "funding_amount": 5000000,
    "total_investors": 5,
    "lead_investors": "Mandiri Capital Indonesia (MCI)"
  },
  {
    "date": "2021-09-07",
    "company_name": "Segari",
    "funding_name": "Series A",
    "funding_amount": 16000000,
    "total_investors": 8,
    "lead_investors": "Go-Ventures"
  },
  {
    "date": "2021-09-07",
    "company_name": "Pasarnow",
    "funding_name": "Seed Round",
    "funding_amount": 3300000,
    "total_investors": 5,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2021-09-10",
    "company_name": "SIRCLO",
    "funding_name": "Series B",
    "funding_amount": 34333333,
    "total_investors": 8,
    "lead_investors": "East Ventures, PT Saratoga Investama Sedaya"
  },
  {
    "date": "2021-09-13",
    "company_name": "Pluang",
    "funding_name": "Series B",
    "funding_amount": 35000000,
    "total_investors": 5,
    "lead_investors": "Square Peg Capital"
  },
  {
    "date": "2021-09-14",
    "company_name": "Xendit",
    "funding_name": "Series C",
    "funding_amount": 150000000,
    "total_investors": 4,
    "lead_investors": "Tiger Global Management"
  },
  {
    "date": "2021-09-14",
    "company_name": "McEasy",
    "funding_name": "Seed Round",
    "funding_amount": 1500000,
    "total_investors": 1,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2021-09-21",
    "company_name": "Evermos",
    "funding_name": "Series B",
    "funding_amount": 30000000,
    "total_investors": 7,
    "lead_investors": "UOB Venture"
  },
  {
    "date": "2021-09-21",
    "company_name": "Komunal",
    "funding_name": "Series A",
    "funding_amount": 2100000,
    "total_investors": 2,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2021-09-23",
    "company_name": "KitaBeli",
    "funding_name": "Series A",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "unknown"
  },
  {
    "date": "2021-09-26",
    "company_name": "Zi.Care",
    "funding_name": "Non Equity Assistance",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Startup Studio Indonesia"
  },
  {
    "date": "2021-10-02",
    "company_name": "Ula",
    "funding_name": "Series B",
    "funding_amount": 87000000,
    "total_investors": 11,
    "lead_investors": "B Capital Group, Prosus Ventures, Tencent"
  },
  {
    "date": "2021-10-04",
    "company_name": "Ajaib",
    "funding_name": "Series B",
    "funding_amount": 153000000,
    "total_investors": 8,
    "lead_investors": "DST Global"
  },
  {
    "date": "2021-10-14",
    "company_name": "iSeller",
    "funding_name": "Series B",
    "funding_amount": 8000000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2021-10-26",
    "company_name": "PrivyID",
    "funding_name": "Series B",
    "funding_amount": 17500000,
    "total_investors": 8,
    "lead_investors": "GGV Capital"
  },
  {
    "date": "2021-10-28",
    "company_name": "Investree",
    "funding_name": "Debt Financing",
    "funding_amount": 10500000,
    "total_investors": 2,
    "lead_investors": "Accial Capital, responsAbility"
  },
  {
    "date": "2021-11-02",
    "company_name": "Astro",
    "funding_name": "Seed Round",
    "funding_amount": 4600000,
    "total_investors": 4,
    "lead_investors": "AC Ventures, Global Founders Capital, Lightspeed Venture Partners"
  },
  {
    "date": "2021-11-08",
    "company_name": "Eden Farm Indonesia",
    "funding_name": "Series A",
    "funding_amount": 19000000,
    "total_investors": 8,
    "lead_investors": "AC Ventures, AppWorks"
  },
  {
    "date": "2021-11-10",
    "company_name": "OnlinePajak",
    "funding_name": "Series C",
    "funding_amount": 5000000,
    "total_investors": 1,
    "lead_investors": "Visa"
  },
  {
    "date": "2021-11-12",
    "company_name": "Bukalapak",
    "funding_name": "Post-IPO Debt",
    "funding_amount": 133333333,
    "total_investors": 1,
    "lead_investors": "PT Bank DBS Indonesia"
  },
  {
    "date": "2021-11-16",
    "company_name": "Ula",
    "funding_name": "Series B",
    "funding_amount": 23000000,
    "total_investors": 2,
    "lead_investors": "Tiger Global Management"
  },
  {
    "date": "2021-11-23",
    "company_name": "Pintek",
    "funding_name": "Series A",
    "funding_amount": 7000000,
    "total_investors": 10,
    "lead_investors": "Heritas Capital, Kaizenvest"
  },
  {
    "date": "2021-11-29",
    "company_name": "GajiGesa",
    "funding_name": "Seed Round",
    "funding_amount": 6300000,
    "total_investors": 13,
    "lead_investors": "MassMutual Ventures Southeast Asia (MMV SEA)"
  },
  {
    "date": "2021-12-02",
    "company_name": "Eureka",
    "funding_name": "Venture Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Mars Growth Capital"
  },
  {
    "date": "2021-12-08",
    "company_name": "Flip",
    "funding_name": "Series B",
    "funding_amount": 48000000,
    "total_investors": 3,
    "lead_investors": "Insight Partners, Insignia Ventures Partners, Sequoia Capital India"
  },
  {
    "date": "2021-12-09",
    "company_name": "Cakap",
    "funding_name": "Series B",
    "funding_amount": 10000000,
    "total_investors": 3,
    "lead_investors": "Centauri Fund, Heritas Capital"
  },
  {
    "date": "2021-12-13",
    "company_name": "Lemonilo",
    "funding_name": "Series C",
    "funding_amount": 34413333,
    "total_investors": 2,
    "lead_investors": "Sofina"
  },
  {
    "date": "2021-12-13",
    "company_name": "Xurya",
    "funding_name": "Series A",
    "funding_amount": 21500000,
    "total_investors": 4,
    "lead_investors": "East Ventures, PT Saratoga Investama Sedaya"
  },
  {
    "date": "2021-12-14",
    "company_name": "Lemonilo",
    "funding_name": "Series C",
    "funding_amount": 36000000,
    "total_investors": 2,
    "lead_investors": "unknown"
  },
  {
    "date": "2021-12-17",
    "company_name": "Otoklix",
    "funding_name": "Series A",
    "funding_amount": 10000000,
    "total_investors": 6,
    "lead_investors": "AC Ventures, Alpha JWC Ventures"
  },
  {
    "date": "2021-12-27",
    "company_name": "Kopi Kenangan",
    "funding_name": "Series C",
    "funding_amount": 96000000,
    "total_investors": 6,
    "lead_investors": "Tybourne Capital Management"
  },
  {
    "date": "2022-01-10",
    "company_name": "KoinWorks",
    "funding_name": "Series C",
    "funding_amount": 43000000,
    "total_investors": 5,
    "lead_investors": "MDI Ventures"
  },
  {
    "date": "2022-01-10",
    "company_name": "KoinWorks",
    "funding_name": "Debt Financing",
    "funding_amount": 65000000,
    "total_investors": 1,
    "lead_investors": "Triodos Investment Management"
  },
  {
    "date": "2022-01-10",
    "company_name": "eFishery",
    "funding_name": "Series C",
    "funding_amount": 90000000,
    "total_investors": 7,
    "lead_investors": "Sequoia Capital India, SoftBank Vision Fund, Temasek Holdings"
  },
  {
    "date": "2022-01-12",
    "company_name": "Pluang",
    "funding_name": "Series C",
    "funding_amount": 55000000,
    "total_investors": 18,
    "lead_investors": "Accel"
  },
  {
    "date": "2022-01-17",
    "company_name": "Moladin",
    "funding_name": "Series A",
    "funding_amount": 42000000,
    "total_investors": 6,
    "lead_investors": "Global Founders Capital, Northstar Group, Sequoia Capital India"
  },
  {
    "date": "2022-01-20",
    "company_name": "Aman",
    "funding_name": "Pre Seed Round",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "unknown"
  },
  {
    "date": "2022-01-24",
    "company_name": "LingoTalk",
    "funding_name": "Seed Round",
    "funding_amount": 500000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2022-01-26",
    "company_name": "CoLearn",
    "funding_name": "Series A",
    "funding_amount": 17000000,
    "total_investors": 9,
    "lead_investors": "TNB AURA"
  },
  {
    "date": "2022-01-26",
    "company_name": "Aruna",
    "funding_name": "Series A",
    "funding_amount": 30000000,
    "total_investors": 7,
    "lead_investors": "Vertex Ventures Southeast Asia & India"
  },
  {
    "date": "2022-01-27",
    "company_name": "Kargo Technologies",
    "funding_name": "Convertible Note",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Teleport"
  },
  {
    "date": "2022-01-27",
    "company_name": "Ayoconnect",
    "funding_name": "Series B",
    "funding_amount": 15000000,
    "total_investors": 2,
    "lead_investors": "Tiger Global Management"
  },
  {
    "date": "2022-01-31",
    "company_name": "Astro",
    "funding_name": "Series A",
    "funding_amount": 27000000,
    "total_investors": 6,
    "lead_investors": "Accel, Sequoia Capital India"
  },
  {
    "date": "2022-02-02",
    "company_name": "CoLearn",
    "funding_name": "Non Equity Assistance",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Endeavor Indonesia"
  },
  {
    "date": "2022-02-02",
    "company_name": "ALAMI",
    "funding_name": "Non Equity Assistance",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Endeavor Indonesia"
  },
  {
    "date": "2022-02-02",
    "company_name": "GajiGesa",
    "funding_name": "Non Equity Assistance",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Endeavor Indonesia"
  },
  {
    "date": "2022-02-04",
    "company_name": "Grupin",
    "funding_name": "Seed Round",
    "funding_amount": 3000000,
    "total_investors": 3,
    "lead_investors": "Surge"
  },
  {
    "date": "2022-02-07",
    "company_name": "JULO",
    "funding_name": "Series B",
    "funding_amount": 60000000,
    "total_investors": 7,
    "lead_investors": "Credit Saison"
  },
  {
    "date": "2022-02-15",
    "company_name": "Akulaku",
    "funding_name": "Series E",
    "funding_amount": 100000000,
    "total_investors": 1,
    "lead_investors": "SCB Group"
  },
  {
    "date": "2022-02-16",
    "company_name": "Ralali",
    "funding_name": "Series D",
    "funding_amount": 10333333,
    "total_investors": 5,
    "lead_investors": "Bee Accelerate, SBI Group"
  },
  {
    "date": "2022-02-16",
    "company_name": "Modalku",
    "funding_name": "Series C",
    "funding_amount": 140000000,
    "total_investors": 9,
    "lead_investors": "SoftBank Vision Fund"
  },
  {
    "date": "2022-02-16",
    "company_name": "Modalku",
    "funding_name": "Debt Financing",
    "funding_amount": 146666667,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2022-03-01",
    "company_name": "Deliveree Logistics",
    "funding_name": "Series C",
    "funding_amount": 70000000,
    "total_investors": 2,
    "lead_investors": "Gobi Partners, SPIL Ventures"
  },
  {
    "date": "2022-03-01",
    "company_name": "Paxel.co",
    "funding_name": "Series B",
    "funding_amount": 9400000,
    "total_investors": 4,
    "lead_investors": "MDI Ventures"
  },
  {
    "date": "2022-03-07",
    "company_name": "Zenius Education",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 5,
    "lead_investors": "MDI Ventures"
  },
  {
    "date": "2022-03-09",
    "company_name": "AwanTunai",
    "funding_name": "Series A",
    "funding_amount": 8100000,
    "total_investors": 7,
    "lead_investors": "International Finance Corporation"
  },
  {
    "date": "2022-03-15",
    "company_name": "wagely",
    "funding_name": "Funding Round",
    "funding_amount": 8300000,
    "total_investors": 7,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2022-03-21",
    "company_name": "Sayurbox",
    "funding_name": "Series C",
    "funding_amount": 113333333,
    "total_investors": 6,
    "lead_investors": "Alpha JWC Ventures, Northstar Group"
  },
  {
    "date": "2022-03-25",
    "company_name": "Cakap",
    "funding_name": "Venture Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Indonesia Impact Fund"
  },
  {
    "date": "2022-03-30",
    "company_name": "Akulaku",
    "funding_name": "Debt Financing",
    "funding_amount": 10000000,
    "total_investors": 1,
    "lead_investors": "Lend East"
  },
  {
    "date": "2022-04-01",
    "company_name": "Hangry",
    "funding_name": "Series A",
    "funding_amount": 22000000,
    "total_investors": 5,
    "lead_investors": "Journey Venture Partners"
  },
  {
    "date": "2022-04-07",
    "company_name": "Shipper",
    "funding_name": "Venture Round",
    "funding_amount": 43700000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2022-04-12",
    "company_name": "waresix",
    "funding_name": "Series B",
    "funding_amount": 50000000,
    "total_investors": 3,
    "lead_investors": "Tiger Global Management"
  },
  {
    "date": "2022-04-13",
    "company_name": "JULO",
    "funding_name": "Corporate Round",
    "funding_amount": 30000000,
    "total_investors": 1,
    "lead_investors": "Credit Saison"
  },
  {
    "date": "2022-04-13",
    "company_name": "JULO",
    "funding_name": "Debt Financing",
    "funding_amount": 50000000,
    "total_investors": 1,
    "lead_investors": "Credit Saison"
  },
  {
    "date": "2022-04-18",
    "company_name": "Hangry",
    "funding_name": "Debt Financing",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "Genesis Alternative Ventures"
  },
  {
    "date": "2022-04-20",
    "company_name": "Moladin",
    "funding_name": "Series B",
    "funding_amount": 95000000,
    "total_investors": 5,
    "lead_investors": "DST Global"
  },
  {
    "date": "2022-04-22",
    "company_name": "Pinhome",
    "funding_name": "Series B",
    "funding_amount": 50000000,
    "total_investors": 8,
    "lead_investors": "Goodwater Capital"
  },
  {
    "date": "2022-04-26",
    "company_name": "ALAMI",
    "funding_name": "Debt Financing",
    "funding_amount": 30000000,
    "total_investors": 1,
    "lead_investors": "Lendable"
  },
  {
    "date": "2022-05-05",
    "company_name": "Pasarnow",
    "funding_name": "Series A",
    "funding_amount": 9500000,
    "total_investors": 2,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2022-05-13",
    "company_name": "Mekari",
    "funding_name": "Series E",
    "funding_amount": 50000000,
    "total_investors": 8,
    "lead_investors": "Money Forward"
  },
  {
    "date": "2022-05-15",
    "company_name": "Aman",
    "funding_name": "Pre Seed Round",
    "funding_amount": 1200000,
    "total_investors": 6,
    "lead_investors": "Global Founders Capital, Trihill Capital"
  },
  {
    "date": "2022-05-19",
    "company_name": "Xendit",
    "funding_name": "Series D",
    "funding_amount": 300000000,
    "total_investors": 9,
    "lead_investors": "Coatue, Insight Partners"
  },
  {
    "date": "2022-05-25",
    "company_name": "Bibit.id",
    "funding_name": "Venture Round",
    "funding_amount": 80000000,
    "total_investors": 2,
    "lead_investors": "GIC"
  },
  {
    "date": "2022-05-30",
    "company_name": "Astro",
    "funding_name": "Series B",
    "funding_amount": 60000000,
    "total_investors": 8,
    "lead_investors": "Accel, Citius, Tiger Global Management"
  },
  {
    "date": "2022-05-31",
    "company_name": "Ringkas",
    "funding_name": "Pre Seed Round",
    "funding_amount": 2300000,
    "total_investors": 5,
    "lead_investors": "500 Global"
  },
  {
    "date": "2022-06-06",
    "company_name": "Pintu",
    "funding_name": "Series B",
    "funding_amount": 106666667,
    "total_investors": 4,
    "lead_investors": "unknown"
  },
  {
    "date": "2022-06-06",
    "company_name": "Mangkokku",
    "funding_name": "Series A",
    "funding_amount": 7000000,
    "total_investors": 3,
    "lead_investors": "Alpha JWC Ventures, Emtek Group"
  },
  {
    "date": "2022-06-14",
    "company_name": "Flip",
    "funding_name": "Series B",
    "funding_amount": 55000000,
    "total_investors": 6,
    "lead_investors": "Tencent"
  },
  {
    "date": "2022-06-15",
    "company_name": "Cermati",
    "funding_name": "Series D",
    "funding_amount": 16686667,
    "total_investors": 2,
    "lead_investors": "MDI Ventures"
  },
  {
    "date": "2022-06-27",
    "company_name": "Populix",
    "funding_name": "Series A",
    "funding_amount": 7700000,
    "total_investors": 4,
    "lead_investors": "Acrew Capital, Intudo Ventures"
  },
  {
    "date": "2022-07-04",
    "company_name": "Paxel.co",
    "funding_name": "Series C",
    "funding_amount": 23000000,
    "total_investors": 7,
    "lead_investors": "Astra Digital"
  },
  {
    "date": "2022-07-05",
    "company_name": "LingoTalk",
    "funding_name": "Seed Round",
    "funding_amount": "unknown",
    "total_investors": 2,
    "lead_investors": "unknown"
  },
  {
    "date": "2022-07-05",
    "company_name": "McEasy",
    "funding_name": "Series A",
    "funding_amount": 6466667,
    "total_investors": 1,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2022-07-17",
    "company_name": "KitaBeli",
    "funding_name": "Venture Round",
    "funding_amount": 20000000,
    "total_investors": 4,
    "lead_investors": "Glade Brook Capital Partners"
  },
  {
    "date": "2022-07-27",
    "company_name": "Traveloka",
    "funding_name": "Corporate Round",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "PTT Oil and Retail Business Public Company"
  },
  {
    "date": "2022-08-01",
    "company_name": "Pashouses.id",
    "funding_name": "Grant",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "Endeavor ScaleUp Growth Program"
  },
  {
    "date": "2022-08-11",
    "company_name": "DANA Wallet Indonesia",
    "funding_name": "Venture Round",
    "funding_amount": 250000000,
    "total_investors": 1,
    "lead_investors": "Sinar Mas Group"
  },
  {
    "date": "2022-08-11",
    "company_name": "DANA Wallet Indonesia",
    "funding_name": "Secondary Market",
    "funding_amount": 304500000,
    "total_investors": 1,
    "lead_investors": "Lazada Group"
  },
  {
    "date": "2022-08-15",
    "company_name": "KUPU",
    "funding_name": "Corporate Round",
    "funding_amount": 5000000,
    "total_investors": 1,
    "lead_investors": "Smartfren"
  },
  {
    "date": "2022-09-04",
    "company_name": "SkorLife",
    "funding_name": "Pre Seed Round",
    "funding_amount": 2200000,
    "total_investors": 8,
    "lead_investors": "AC Ventures"
  },
  {
    "date": "2022-09-14",
    "company_name": "Nusantics",
    "funding_name": "Non Equity Assistance",
    "funding_amount": "unknown",
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2022-09-19",
    "company_name": "Ralali",
    "funding_name": "Series D",
    "funding_amount": 2000000,
    "total_investors": 1,
    "lead_investors": "ACA Investments"
  },
  {
    "date": "2022-09-21",
    "company_name": "HappyFresh",
    "funding_name": "Venture Round",
    "funding_amount": "unknown",
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2022-09-22",
    "company_name": "Mangkokku",
    "funding_name": "Venture Round",
    "funding_amount": 2200000,
    "total_investors": 1,
    "lead_investors": "PT karunia pangan indonesia"
  },
  {
    "date": "2022-09-29",
    "company_name": "Traveloka",
    "funding_name": "Debt Financing",
    "funding_amount": 300000000,
    "total_investors": 4,
    "lead_investors": "BlackRock"
  },
  {
    "date": "2022-09-29",
    "company_name": "Alodokter",
    "funding_name": "Venture Round",
    "funding_amount": 3000000,
    "total_investors": 1,
    "lead_investors": "Samsung Venture Investment"
  },
  {
    "date": "2022-10-07",
    "company_name": "eFishery",
    "funding_name": "Debt Financing",
    "funding_amount": 33333333,
    "total_investors": 1,
    "lead_investors": "PT Bank DBS Indonesia"
  },
  {
    "date": "2022-10-08",
    "company_name": "Wahyoo",
    "funding_name": "Series B",
    "funding_amount": 6500000,
    "total_investors": 8,
    "lead_investors": "Eugene Investment"
  },
  {
    "date": "2022-10-10",
    "company_name": "ALAMI",
    "funding_name": "Series B",
    "funding_amount": "unknown",
    "total_investors": 5,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2022-10-11",
    "company_name": "FinAccel",
    "funding_name": "Series D",
    "funding_amount": 140000000,
    "total_investors": 7,
    "lead_investors": "Mirae Asset"
  },
  {
    "date": "2022-10-11",
    "company_name": "Sociolla",
    "funding_name": "Venture Round",
    "funding_amount": 60000000,
    "total_investors": 4,
    "lead_investors": "L Catterton Asia, Temasek Holdings"
  },
  {
    "date": "2022-10-13",
    "company_name": "Ayoconnect",
    "funding_name": "Series B",
    "funding_amount": 13000000,
    "total_investors": 3,
    "lead_investors": "Susquehanna International Group (SIG)"
  },
  {
    "date": "2022-10-20",
    "company_name": "Xurya",
    "funding_name": "Series A",
    "funding_amount": 11500000,
    "total_investors": 2,
    "lead_investors": "Surya Semesta Internusa"
  },
  {
    "date": "2022-11-13",
    "company_name": "PrivyID",
    "funding_name": "Series C",
    "funding_amount": 48000000,
    "total_investors": 5,
    "lead_investors": "Kohlberg Kravis Roberts"
  },
  {
    "date": "2022-11-15",
    "company_name": "Erasco",
    "funding_name": "Series A",
    "funding_amount": 1100000,
    "total_investors": 2,
    "lead_investors": "unknown"
  },
  {
    "date": "2022-11-16",
    "company_name": "Dekoruma",
    "funding_name": "Venture Round",
    "funding_amount": "unknown",
    "total_investors": 4,
    "lead_investors": "PT. Djarum"
  },
  {
    "date": "2022-11-17",
    "company_name": "Pashouses.id",
    "funding_name": "Series B",
    "funding_amount": 5000000,
    "total_investors": 1,
    "lead_investors": "QED Investors"
  },
  {
    "date": "2023-01-26",
    "company_name": "iSeller",
    "funding_name": "Series B",
    "funding_amount": 12000000,
    "total_investors": 4,
    "lead_investors": "Intudo Ventures"
  },
  {
    "date": "2023-04-13",
    "company_name": "Zi.Care",
    "funding_name": "Series A",
    "funding_amount": 2000000,
    "total_investors": 1,
    "lead_investors": "Oriza Greenwillow Technology Fund"
  },
  {
    "date": "2023-05-03",
    "company_name": "Coldspace",
    "funding_name": "Seed Round",
    "funding_amount": 3800000,
    "total_investors": 4,
    "lead_investors": "Intudo Ventures, Triputra Group"
  },
  {
    "date": "2023-05-03",
    "company_name": "Erasco",
    "funding_name": "Series B",
    "funding_amount": 215000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2023-05-24",
    "company_name": "SkorLife",
    "funding_name": "Seed Round",
    "funding_amount": 4000000,
    "total_investors": 4,
    "lead_investors": "Hummingbird Ventures"
  },
  {
    "date": "2023-05-31",
    "company_name": "Ringkas",
    "funding_name": "Seed Round",
    "funding_amount": 3500000,
    "total_investors": 6,
    "lead_investors": "Crestone Venture Capital, East Ventures"
  },
  {
    "date": "2023-06-02",
    "company_name": "Rekava",
    "funding_name": "Pre-Seed",
    "funding_amount": 175000,
    "total_investors": 1,
    "lead_investors": "Antler"
  },
  {
    "date": "2023-06-06",
    "company_name": "PT ALBA Tridi Plastics Recycling Indonesia",
    "funding_name": "Debt Financing",
    "funding_amount": 44200000,
    "total_investors": 1,
    "lead_investors": "Asian Development Bank"
  },
  {
    "date": "2023-06-14",
    "company_name": "Finfra",
    "funding_name": "Seed",
    "funding_amount": 1000000,
    "total_investors": 7,
    "lead_investors": "unknown"
  },
  {
    "date": "2023-06-16",
    "company_name": "Amartha",
    "funding_name": "Debt Financing",
    "funding_amount": 100000000,
    "total_investors": 1,
    "lead_investors": "Community Investment Management"
  },
  {
    "date": "2023-06-16",
    "company_name": "SIRCLO",
    "funding_name": "Series C",
    "funding_amount": 10500000,
    "total_investors": 1,
    "lead_investors": "Vertex Ventures Southeast Asia & India"
  },
  {
    "date": "2023-06-21",
    "company_name": "Slice Group",
    "funding_name": "Seed",
    "funding_amount": 645000,
    "total_investors": 3,
    "lead_investors": "Arise, Intudo Ventures"
  },
  {
    "date": "2023-06-22",
    "company_name": "TransTRACK.ID",
    "funding_name": "Seed",
    "funding_amount": 2100000,
    "total_investors": 6,
    "lead_investors": "Ortus Star"
  },
  {
    "date": "2023-06-22",
    "company_name": "Bank BTPN",
    "funding_name": "Post-IPO Debt",
    "funding_amount": 500000000,
    "total_investors": 1,
    "lead_investors": "International Finance Corporation"
  },
  {
    "date": "2023-06-27",
    "company_name": "Baskit",
    "funding_name": "Seed",
    "funding_amount": 4100000,
    "total_investors": 9,
    "lead_investors": "Betatron Venture Group, Forge Ventures"
  },
  {
    "date": "2023-06-27",
    "company_name": "Honest",
    "funding_name": "Venture - Series Unknown",
    "funding_amount": 2000000,
    "total_investors": 1,
    "lead_investors": "Orient Corporation"
  },
  {
    "date": "2023-07-01",
    "company_name": "Pinhome",
    "funding_name": "Debt Financing",
    "funding_amount": 5000000,
    "total_investors": 1,
    "lead_investors": "Genesis Alternative Ventures"
  },
  {
    "date": "2023-07-03",
    "company_name": "eFishery",
    "funding_name": "Series D",
    "funding_amount": 92000000,
    "total_investors": 7,
    "lead_investors": "G42 Expansion Fund"
  },
  {
    "date": "2023-07-07",
    "company_name": "Eisen Precision",
    "funding_name": "Seed",
    "funding_amount": 100000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2023-07-10",
    "company_name": "Eratani",
    "funding_name": "Seed",
    "funding_amount": 2000000,
    "total_investors": 4,
    "lead_investors": "SBI Ven Capital"
  },
  {
    "date": "2023-07-19",
    "company_name": "Maka Motors",
    "funding_name": "Seed",
    "funding_amount": 37600000,
    "total_investors": 13,
    "lead_investors": "AC Ventures, East Ventures, SV Investment Corp"
  },
  {
    "date": "2023-07-27",
    "company_name": "AdaKami",
    "funding_name": "Debt Financing",
    "funding_amount": 21428571,
    "total_investors": 1,
    "lead_investors": "SeaBank Indonesia"
  },
  {
    "date": "2023-07-28",
    "company_name": "Halodoc",
    "funding_name": "Series D",
    "funding_amount": 100000000,
    "total_investors": 3,
    "lead_investors": "Astra Digital"
  },
  {
    "date": "2023-07-30",
    "company_name": "KelasDigitalk.com",
    "funding_name": "Seed",
    "funding_amount": 150000,
    "total_investors": 1,
    "lead_investors": "8Ventures"
  },
  {
    "date": "2023-07-30",
    "company_name": "BroilerX",
    "funding_name": "Seed",
    "funding_amount": 1300000,
    "total_investors": 1,
    "lead_investors": "Insignia Ventures Partners"
  },
  {
    "date": "2023-07-31",
    "company_name": "DINOTIS",
    "funding_name": "Non-equity Assistance",
    "funding_amount": 100000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2023-08-01",
    "company_name": "Beleaf",
    "funding_name": "Series A",
    "funding_amount": 6850000,
    "total_investors": 2,
    "lead_investors": "Alpha JWC Ventures"
  },
  {
    "date": "2023-08-02",
    "company_name": "Genexyz",
    "funding_name": "Seed",
    "funding_amount": 1000000,
    "total_investors": 6,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2023-08-02",
    "company_name": "BintanGO",
    "funding_name": "Seed",
    "funding_amount": 2200000,
    "total_investors": 2,
    "lead_investors": "unknown"
  },
  {
    "date": "2023-08-03",
    "company_name": "Rekosistem",
    "funding_name": "Seed",
    "funding_amount": 5000000,
    "total_investors": 3,
    "lead_investors": "Skystar Capital"
  },
  {
    "date": "2023-08-09",
    "company_name": "GORO",
    "funding_name": "Pre-Seed",
    "funding_amount": 1000000,
    "total_investors": 3,
    "lead_investors": "Iterative"
  },
  {
    "date": "2023-08-14",
    "company_name": "VKTR",
    "funding_name": "Post-IPO Debt",
    "funding_amount": 17857143,
    "total_investors": 1,
    "lead_investors": "Bank Central Asia"
  },
  {
    "date": "2023-08-28",
    "company_name": "Charged",
    "funding_name": "Seed",
    "funding_amount": 40000000,
    "total_investors": 1,
    "lead_investors": "Geo Energy Resources"
  },
  {
    "date": "2023-08-31",
    "company_name": "Staffinc",
    "funding_name": "Series B",
    "funding_amount": 3900000,
    "total_investors": 5,
    "lead_investors": "Altara Ventures"
  },
  {
    "date": "2023-09-01",
    "company_name": "ESKRO",
    "funding_name": "Pre-Seed",
    "funding_amount": 200000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2023-09-03",
    "company_name": "KUPU",
    "funding_name": "Seed",
    "funding_amount": 6000000,
    "total_investors": 1,
    "lead_investors": "AGI Partners"
  },
  {
    "date": "2023-09-06",
    "company_name": "DELOS",
    "funding_name": "Series A",
    "funding_amount": 5750000,
    "total_investors": 1,
    "lead_investors": "Monk’s Hill Ventures"
  },
  {
    "date": "2023-09-08",
    "company_name": "Club Kyta",
    "funding_name": "Pre-Seed",
    "funding_amount": 125000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2023-09-08",
    "company_name": "Manuva",
    "funding_name": "Series B",
    "funding_amount": 8000000,
    "total_investors": 1,
    "lead_investors": "Tin Men Capital"
  },
  {
    "date": "2023-09-12",
    "company_name": "Hazana",
    "funding_name": "Pre-Seed",
    "funding_amount": 125000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2023-09-12",
    "company_name": "Plans",
    "funding_name": "Pre-Seed",
    "funding_amount": 125000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2023-09-12",
    "company_name": "SafeLog.ai",
    "funding_name": "Pre-Seed",
    "funding_amount": 125000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2023-09-13",
    "company_name": "Zi.Care",
    "funding_name": "Series A",
    "funding_amount": 1340000,
    "total_investors": 4,
    "lead_investors": "unknown"
  },
  {
    "date": "2023-09-13",
    "company_name": "PT SMI",
    "funding_name": "Debt Financing",
    "funding_amount": 760714286,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2023-09-13",
    "company_name": "Amartha",
    "funding_name": "Debt Financing",
    "funding_amount": 214285714,
    "total_investors": 1,
    "lead_investors": "International Finance Corporation"
  },
  {
    "date": "2023-09-15",
    "company_name": "KORA",
    "funding_name": "Pre-Seed",
    "funding_amount": 400000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2023-09-15",
    "company_name": "Segari",
    "funding_name": "Series B",
    "funding_amount": 23500000,
    "total_investors": 3,
    "lead_investors": "unknown"
  },
  {
    "date": "2023-09-15",
    "company_name": "kora.id",
    "funding_name": "Pre-Seed",
    "funding_amount": 400000,
    "total_investors": 2,
    "lead_investors": "Gibran Huzaifah"
  },
  {
    "date": "2023-09-18",
    "company_name": "Kin dairy",
    "funding_name": "Private Equity",
    "funding_amount": 70000000,
    "total_investors": 1,
    "lead_investors": "Growtheum Capital Partners"
  },
  {
    "date": "2023-09-19",
    "company_name": "Bababos",
    "funding_name": "Seed",
    "funding_amount": 3292857,
    "total_investors": 3,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2023-09-26",
    "company_name": "Modalku",
    "funding_name": "Debt Financing",
    "funding_amount": 38700000,
    "total_investors": 4,
    "lead_investors": "AlteriQ Capital"
  },
  {
    "date": "2023-09-27",
    "company_name": "Carepedia",
    "funding_name": "Pre-Seed",
    "funding_amount": 125000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2023-09-28",
    "company_name": "Alter",
    "funding_name": "Pre-Seed",
    "funding_amount": 125000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2023-09-28",
    "company_name": "Sqouts",
    "funding_name": "Pre-Seed",
    "funding_amount": 125000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2023-10-03",
    "company_name": "GoTo Group",
    "funding_name": "Debt Financing",
    "funding_amount": 150000000,
    "total_investors": 2,
    "lead_investors": "International Finance Corporation"
  },
  {
    "date": "2023-10-04",
    "company_name": "Investree",
    "funding_name": "Series D",
    "funding_amount": 257142857,
    "total_investors": 2,
    "lead_investors": "JTA International Investment Holding"
  },
  {
    "date": "2023-10-09",
    "company_name": "PT Good Doctor Technology Indonesia",
    "funding_name": "Series A",
    "funding_amount": 10000000,
    "total_investors": 3,
    "lead_investors": "MDI Ventures"
  },
  {
    "date": "2023-10-10",
    "company_name": "Demuzo",
    "funding_name": "Pre-Seed",
    "funding_amount": 600000,
    "total_investors": 3,
    "lead_investors": "Evgenii Dar"
  },
  {
    "date": "2023-10-11",
    "company_name": "KarmaClub",
    "funding_name": "Seed",
    "funding_amount": 14000000,
    "total_investors": 3,
    "lead_investors": "unknown"
  },
  {
    "date": "2023-10-19",
    "company_name": "DINOTIS",
    "funding_name": "Angel",
    "funding_amount": 200000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2023-10-20",
    "company_name": "MedcoEnergi",
    "funding_name": "Post-IPO Debt",
    "funding_amount": 500000000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2023-11-01",
    "company_name": "Blitz Electric Mobility",
    "funding_name": "Seed",
    "funding_amount": 1600000,
    "total_investors": 7,
    "lead_investors": "ADB Ventures, Iterative"
  },
  {
    "date": "2023-11-15",
    "company_name": "Dash Electric",
    "funding_name": "Pre-Seed",
    "funding_amount": 125000,
    "total_investors": 1,
    "lead_investors": "Antler"
  },
  {
    "date": "2023-11-16",
    "company_name": "Pawprints Inspired",
    "funding_name": "Seed",
    "funding_amount": 1700000,
    "total_investors": 3,
    "lead_investors": "Creative Gorilla Capital"
  },
  {
    "date": "2023-11-20",
    "company_name": "Honest",
    "funding_name": "Corporate Round",
    "funding_amount": 18000000,
    "total_investors": 1,
    "lead_investors": "Orient Corporation"
  },
  {
    "date": "2023-11-22",
    "company_name": "Terratai",
    "funding_name": "Seed",
    "funding_amount": 2214286,
    "total_investors": 2,
    "lead_investors": "Swiss Re Foundation, UBS"
  },
  {
    "date": "2023-11-23",
    "company_name": "Kamoo",
    "funding_name": "Pre-Seed",
    "funding_amount": 125000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2023-11-27",
    "company_name": "Jala Tech",
    "funding_name": "Series A",
    "funding_amount": 16130000,
    "total_investors": 4,
    "lead_investors": "Intudo Ventures"
  },
  {
    "date": "2023-11-28",
    "company_name": "Loop",
    "funding_name": "Seed",
    "funding_amount": 200000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2023-11-30",
    "company_name": "FR8Labs",
    "funding_name": "Seed",
    "funding_amount": 1642857,
    "total_investors": 6,
    "lead_investors": "East Ventures"
  },
  {
    "date": "2023-12-01",
    "company_name": "FishLog",
    "funding_name": "Grant",
    "funding_amount": 100000,
    "total_investors": 2,
    "lead_investors": "Ministry of Finance, Indonesia, UNDP"
  },
  {
    "date": "2023-12-04",
    "company_name": "Gravel",
    "funding_name": "Venture - Series Unknown",
    "funding_amount": 15428571,
    "total_investors": 5,
    "lead_investors": "New Enterprise Associates"
  },
  {
    "date": "2023-12-04",
    "company_name": "Alner",
    "funding_name": "Grant",
    "funding_amount": 145000,
    "total_investors": 1,
    "lead_investors": "Strive Community Innovation Fund"
  },
  {
    "date": "2023-12-08",
    "company_name": "Rose All Day",
    "funding_name": "Series A",
    "funding_amount": 5410000,
    "total_investors": 3,
    "lead_investors": "SWC Global"
  },
  {
    "date": "2023-12-11",
    "company_name": "Klinik Pintar",
    "funding_name": "Series A",
    "funding_amount": 5000000,
    "total_investors": 5,
    "lead_investors": "Altara Ventures"
  },
  {
    "date": "2023-12-12",
    "company_name": "Chandra Asri",
    "funding_name": "Post-IPO Equity",
    "funding_amount": 194000000,
    "total_investors": 1,
    "lead_investors": "Electricity Generating Public Company"
  },
  {
    "date": "2023-12-15",
    "company_name": "Lunash",
    "funding_name": "Pre-Seed",
    "funding_amount": 125000,
    "total_investors": 1,
    "lead_investors": "Antler"
  },
  {
    "date": "2023-12-20",
    "company_name": "Home Credit Indonesia",
    "funding_name": "Corporate Round",
    "funding_amount": 100000000,
    "total_investors": 1,
    "lead_investors": "Mitsubishi UFJ Financial Group"
  },
  {
    "date": "2024-01-01",
    "company_name": "Konstruksi.AI",
    "funding_name": "Pre-Seed",
    "funding_amount": 125000,
    "total_investors": 1,
    "lead_investors": "Antler"
  },
  {
    "date": "2024-01-01",
    "company_name": "Aisyah Beverages Company (Backed by Microsoft USA HQ)",
    "funding_name": "Seed",
    "funding_amount": 1092000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2024-01-02",
    "company_name": "Komunal",
    "funding_name": "Series A",
    "funding_amount": 5500000,
    "total_investors": 7,
    "lead_investors": "Sumitomo"
  },
  {
    "date": "2024-01-02",
    "company_name": "Swap",
    "funding_name": "Series A",
    "funding_amount": 22000000,
    "total_investors": 3,
    "lead_investors": "Qiming Venture Partners"
  },
  {
    "date": "2024-01-04",
    "company_name": "SUN Energy",
    "funding_name": "Debt Financing",
    "funding_amount": 21000000,
    "total_investors": 1,
    "lead_investors": "DEG"
  },
  {
    "date": "2024-01-08",
    "company_name": "SPUN",
    "funding_name": "Pre-Seed",
    "funding_amount": 125000,
    "total_investors": 1,
    "lead_investors": "Antler"
  },
  {
    "date": "2024-01-15",
    "company_name": "Coalignment",
    "funding_name": "Pre-Seed",
    "funding_amount": 1000000,
    "total_investors": "—",
    "lead_investors": "unknown"
  },
  {
    "date": "2024-01-16",
    "company_name": "DayaTani",
    "funding_name": "Seed",
    "funding_amount": 2300000,
    "total_investors": 6,
    "lead_investors": "Ascent Venture Group, KBI Global Investors"
  },
  {
    "date": "2024-01-22",
    "company_name": "Ayoconnect",
    "funding_name": "Venture - Series Unknown",
    "funding_amount": 2500000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2024-01-22",
    "company_name": "YOBO",
    "funding_name": "Pre-Seed",
    "funding_amount": 5100000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2024-01-30",
    "company_name": "Ternakin",
    "funding_name": "Pre-Seed",
    "funding_amount": 125000,
    "total_investors": 1,
    "lead_investors": "Antler"
  },
  {
    "date": "2024-01-30",
    "company_name": "AssetFindr",
    "funding_name": "Pre-Seed",
    "funding_amount": 125000,
    "total_investors": 1,
    "lead_investors": "Antler"
  },
  {
    "date": "2024-02-01",
    "company_name": "PINTAR",
    "funding_name": "Seed",
    "funding_amount": 3000000,
    "total_investors": 2,
    "lead_investors": "Havez Capital"
  },
  {
    "date": "2024-02-07",
    "company_name": "Gently",
    "funding_name": "Seed",
    "funding_amount": 2500000,
    "total_investors": 3,
    "lead_investors": "unknown"
  },
  {
    "date": "2024-02-16",
    "company_name": "Buana Finance",
    "funding_name": "Post-IPO Debt",
    "funding_amount": 14285714,
    "total_investors": 1,
    "lead_investors": "PT Bank KEB Hana Indonesia"
  },
  {
    "date": "2024-02-21",
    "company_name": "Alodokter",
    "funding_name": "Series D",
    "funding_amount": 5200000,
    "total_investors": 2,
    "lead_investors": "unknown"
  },
  {
    "date": "2024-02-26",
    "company_name": "Citra Borneo Utama",
    "funding_name": "Post-IPO Debt",
    "funding_amount": 71428571,
    "total_investors": 1,
    "lead_investors": "Bank Rakyat Indonesia"
  },
  {
    "date": "2024-02-29",
    "company_name": "SUN Energy",
    "funding_name": "Debt Financing",
    "funding_amount": 32000000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2024-03-04",
    "company_name": "Wagely",
    "funding_name": "Venture - Series Unknown",
    "funding_amount": 23000000,
    "total_investors": 1,
    "lead_investors": "Capria Ventures"
  },
  {
    "date": "2024-03-07",
    "company_name": "Rukita",
    "funding_name": "Series B",
    "funding_amount": 16750000,
    "total_investors": 9,
    "lead_investors": "unknown"
  },
  {
    "date": "2024-03-08",
    "company_name": "SnowSeed",
    "funding_name": "Seed",
    "funding_amount": 5000000,
    "total_investors": 2,
    "lead_investors": "Medi Crypto International, PT Buckingham Holdings"
  },
  {
    "date": "2024-03-12",
    "company_name": "AwanTunai",
    "funding_name": "Series B",
    "funding_amount": 27500000,
    "total_investors": 3,
    "lead_investors": "Finnfund, MUFG Innovation Partners, Norfund"
  },
  {
    "date": "2024-03-22",
    "company_name": "Akulaku Group",
    "funding_name": "Debt Financing",
    "funding_amount": 100000000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2024-03-27",
    "company_name": "Qoala",
    "funding_name": "Series C",
    "funding_amount": 50950000,
    "total_investors": 8,
    "lead_investors": "MassMutual Ventures, PayPal Ventures"
  },
  {
    "date": "2024-04-01",
    "company_name": "Chandra Asri",
    "funding_name": "Post-IPO Debt",
    "funding_amount": 285714286,
    "total_investors": 1,
    "lead_investors": "Bank Mandiri"
  },
  {
    "date": "2024-04-04",
    "company_name": "PT Multifinance Anak Bangsa",
    "funding_name": "Corporate Round",
    "funding_amount": 2317857,
    "total_investors": 1,
    "lead_investors": "GoTo Group"
  },
  {
    "date": "2024-04-16",
    "company_name": "Jago Coffee",
    "funding_name": "Series A",
    "funding_amount": 6000000,
    "total_investors": 4,
    "lead_investors": "BEENEXT, Intudo Ventures"
  },
  {
    "date": "2024-04-24",
    "company_name": "Bank Nationalnobu",
    "funding_name": "Post-IPO Equity",
    "funding_amount": 40000000,
    "total_investors": 1,
    "lead_investors": "Hanwha Life"
  },
  {
    "date": "2024-05-02",
    "company_name": "Jejakin",
    "funding_name": "Seed",
    "funding_amount": 2700000,
    "total_investors": 6,
    "lead_investors": "unknown"
  },
  {
    "date": "2024-05-07",
    "company_name": "Runchise",
    "funding_name": "Seed",
    "funding_amount": 1000000,
    "total_investors": 2,
    "lead_investors": "East Ventures, Genesia Ventures"
  },
  {
    "date": "2024-05-08",
    "company_name": "Elevarm",
    "funding_name": "Seed",
    "funding_amount": 2978571,
    "total_investors": 3,
    "lead_investors": "Insignia Ventures Partners"
  },
  {
    "date": "2024-05-09",
    "company_name": "Gapai",
    "funding_name": "Seed",
    "funding_amount": 1000000,
    "total_investors": 2,
    "lead_investors": "Wavemaker Partners"
  },
  {
    "date": "2024-05-22",
    "company_name": "Dagangan",
    "funding_name": "Venture - Series Unknown",
    "funding_amount": 18500000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2024-05-22",
    "company_name": "Honest",
    "funding_name": "Series B",
    "funding_amount": 2500000,
    "total_investors": 2,
    "lead_investors": "Rakuten Ventures"
  },
  {
    "date": "2024-05-23",
    "company_name": "Skuling",
    "funding_name": "Pre-Seed",
    "funding_amount": 100000,
    "total_investors": 1,
    "lead_investors": "unknown"
  },
  {
    "date": "2024-05-29",
    "company_name": "NWP Property",
    "funding_name": "Debt Financing",
    "funding_amount": 53000000,
    "total_investors": 1,
    "lead_investors": "International Finance Corporation"
  },
  {
    "date": "2024-05-30",
    "company_name": "eFishery",
    "funding_name": "Debt Financing",
    "funding_amount": 30000000,
    "total_investors": 1,
    "lead_investors": "PT Bank HSBC Indonesia"
  },
  {
    "date": "2024-06-05",
    "company_name": "McEasy",
    "funding_name": "Series A",
    "funding_amount": 5785714,
    "total_investors": 2,
    "lead_investors": "GGV Capital"
  }
]